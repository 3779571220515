import {
  DigitalTwinSection,
  EnergyChart,
  HStack,
  RecommendationsAccordion,
  SharePanel,
  Text3Xl,
  TextBase,
  TextLg,
  TextRXl,
  VStack,
} from "@seeair/shared-components";
import React, { ReactNode, useEffect, useState } from "react";
import { Assessment, HomeAggregate, HomeOwnerTab } from "@seeair/schemas";
import { SegmentedControl, Tabs } from "@mantine/core";
import {
  buildShortAddress,
  deriveElectrificationData,
  formatMoney,
  formatNumber,
  getLatestFile,
  getLatestFinishedAssessment,
  getNormalizedEnergyUsage,
  kwhToTherms,
  validateAssessmentForAnalysis,
} from "@seeair/shared";
import {
  Icon3dCubeSphere,
  IconBolt,
  IconHammer,
  IconShare3,
} from "@tabler/icons-react";

type Show = "usage" | "cost";

export function HomePortal({
  home,tab,selectedProjectId
}: {
  home: HomeAggregate,
  tab: HomeOwnerTab,
  selectedProjectId?: string
}) {
  const initiallyShowViewer = tab == 'viewer'
  const [selectedTab, setSelectedTab] = useState(tab)
  useEffect(() => {
    setSelectedTab(tab)
  }, [tab])

  const latestAssessment = getLatestFinishedAssessment(home);
  return (
    <VStack classNames="flex-grow bg-white">
      <HStack center>
        <TextRXl>{buildShortAddress(home.address)}</TextRXl>
      </HStack>
      <Tabs
        value={selectedTab}
        onChange={async (value) => {
          setSelectedTab((value ?? 'projects') as HomeOwnerTab)
          const url = new URL(window.location.href);
          if (value) {
            url.searchParams.set('tab', value)
            window.history.pushState({}, '', url)
          }
        }}
        classNames={{ tab: `border border-gray-500 aria-selected:bg-gray-200` }}
      >
        <Tabs.List className="justify-center" classNames={{}}>
          <Tabs.Tab value="energy" leftSection={<IconBolt />}>
            Energy Usage
          </Tabs.Tab>
          <Tabs.Tab value="viewer" leftSection={<Icon3dCubeSphere />}>
            Digital Twin
          </Tabs.Tab>
          <Tabs.Tab value="plan" leftSection={<IconHammer />}>
            Improvement Plan
          </Tabs.Tab>
          {(home.authorization.home?.has("owner") ?? false) && (
            <Tabs.Tab value="share" leftSection={<IconShare3 />}>
              Share
            </Tabs.Tab>
          )}
        </Tabs.List>
        <Tabs.Panel value="energy">
          <HomePortalEnergy assessment={latestAssessment} />
        </Tabs.Panel>
        <Tabs.Panel value="viewer">
          <HomePortalViewer
            latestAssessment={latestAssessment}
            initiallyShowViewer={!!initiallyShowViewer}
            home={home}
          />
        </Tabs.Panel>
        <Tabs.Panel value="plan">
          <HomePortalPlan home={home} selectedProjectId={selectedProjectId}/>
        </Tabs.Panel>
        <Tabs.Panel value="share">
          <SharePanel home={home} />
        </Tabs.Panel>
      </Tabs>
    </VStack>
  );
}

function HomePortalPlan({ home,selectedProjectId }: { home: HomeAggregate,selectedProjectId?:string }) {
  return (
    <VStack center>
      <VStack classNames="max-w-screen-xl">
        {home.recommendations.length == 0 ? (
          <TextBase>
            Your home is healthy and energy efficient, thanks for doing your
            part to save the world!
          </TextBase>
        ) : (
          <RecommendationsAccordion
            recs={home.recommendations}
            projects={home.projects}
            filteredRecs={[]}
            includeProjects={true}
            selectedProjectId={selectedProjectId}
            isContractor={false}
            navigateToProject={() => {}}
          />
        )}
      </VStack>
    </VStack>
  );
}

function HomePortalViewer({
  home,
  latestAssessment,
  initiallyShowViewer,
}: {
  initiallyShowViewer: boolean;
  home: HomeAggregate;
  latestAssessment: Assessment | "not_found";
}) {
  let latestRenderingFile;
  if (latestAssessment != "not_found") {
    latestRenderingFile = getLatestFile(
      latestAssessment,
      "capture_rendering_enhanced",
    );
  }
  return (
    <VStack center>
      <Text3Xl light center>
        Digital Twin
      </Text3Xl>
      <DigitalTwinSection
        file={latestRenderingFile}
        home={home}
        initiallyShowViewer={initiallyShowViewer}
        isContractor={false}
      />
    </VStack>
  );
}

function HomePortalEnergy({
  assessment,
}: {
  assessment: Assessment | "not_found";
}) {
  const [show, setShow] = useState("cost" as Show);
  const [allMonths, setAllMonths] = useState(false);
  let content: ReactNode;
  try {
    if (assessment == "not_found") {
      throw "assessment not_found";
    }
    validateAssessmentForAnalysis(assessment);
    const normalizedEnergyUse = getNormalizedEnergyUsage(
      assessment.home_details!,
      assessment.electric_usage ?? {},
      assessment.gas_usage ?? {},
      assessment.oil_usage ?? {},
    );
    const electrification = deriveElectrificationData(
      assessment.home_details!,
      normalizedEnergyUse,
    );
    const hasGas = electrification.energy_use.Gas > 0;
    const hasOil = electrification.energy_use.Oil > 0;
    let electricAnnual, gasAnnual, oilAnnual;
    if (show == "usage") {
      electricAnnual = `${formatNumber(electrification.energy_use.Electric, 0)} kWh`;
      gasAnnual = `${formatNumber(kwhToTherms(electrification.energy_use.Gas ?? 0), 0)} Therms`;
      oilAnnual = `${formatNumber(kwhToTherms(electrification.energy_use.Oil ?? 0), 0)} Therms`;
    } else {
      electricAnnual = `${formatMoney(electrification.energy_cost.Electric)}`;
      gasAnnual = `${formatMoney(electrification.energy_cost.Gas ?? 0)}`;
      oilAnnual = `${formatMoney(electrification.energy_cost.Oil ?? 0)}`;
    }
    content = (
      <React.Fragment>
        <HStack baseline center>
          <TextLg classNames="pr-4">Annual Electric Usage:&nbsp;</TextLg>
          <TextLg light>{electricAnnual}</TextLg>
          {hasGas && (
            <React.Fragment>
              <TextLg classNames="pr-4 pl-8">Annual Gas Usage:&nbsp;</TextLg>
              <TextLg light>{gasAnnual}</TextLg>
            </React.Fragment>
          )}
          {hasOil && (
            <React.Fragment>
              <TextLg classNames="pr-4 pl-8">Annual Oil Usage:&nbsp;</TextLg>
              <TextLg light>{oilAnnual}</TextLg>
            </React.Fragment>
          )}
        </HStack>
        <EnergyChart electrification={electrification} show={show} allMonths={allMonths} />
      </React.Fragment>
    );
  } catch (e) {
    console.log(`Assessment Not ready: ${e}`);
    content = <TextBase>Energy Data Missing</TextBase>;
  }

  return (
    <VStack center>
      <HStack center>
        <Text3Xl light center>
          Heating & Cooling Energy Costs
        </Text3Xl>
        <SegmentedControl
          className="ml-8"
          value={show}
          onChange={(v) => setShow(v as Show)}
          data={[
            { label: "Cost", value: "cost" },
            {
              label: "Usage",
              value: "usage",
            },
          ]}
        />
        <SegmentedControl
          className="ml-8"
          value={allMonths ? 'all' : '12m'}
          onChange={(v) => setAllMonths(v == 'all')}
          data={[
            { label: "One Year", value: "12m" },
            {
              label: "All",
              value: "all",
            },
          ]}
        />
      </HStack>
      {content}
    </VStack>
  );
}
