import {COLOR_WHITE, GRADIENT_NAVY, PRIMARY_BLUE, PRIMARY_LIGHT_BLUE} from './Theme.js';
import React, {PropsWithChildren, ReactElement, ReactNode, useEffect, useState} from "react";
import {AbsoluteCenterStack, HStack, Text3Xl, Text5Xl, TextSm, TextXl, VStack} from './DesignBase.js';
import classNames from "classnames";
import {Link} from "@tanstack/react-router";
import {
    IconCancel, IconDeviceFloppy,
    IconInfoCircle,
    IconPencil,
    IconPlayerPlay,
    IconX
} from "@tabler/icons-react";
import {Button, Combobox, Divider, InputBase, Loader, Tooltip, UnstyledButton, useCombobox} from "@mantine/core";
import {
    dateArrayToIsoStringArray,
    dateToIsoString,
    hashToNumber, isoStringArrayContainsDate,
    isoStringArrayToDateArray,
    isoStringToDate
} from "@seeair/shared";
import {DatePicker} from "@mantine/dates";

export function DesignedTwoColumnCard({imgSrc, title, text, linkTo, linkText, wide}: {
    imgSrc: string,
    title: string,
    text: string,
    linkTo?: string,
    linkText?: string
    wide?: boolean
}) {
    return <div
        className={classNames("my-8 rounded-2xl mx-2 sm:mx-12 flex flex-col md:flex-row justify-between items-center", {
            "w-max-200": !wide,
            "md:w-200": !wide,
        })}
        style={{
            background: "linear-gradient(119deg, rgba(0, 121, 233, 0.00) 46.36%, rgba(0, 121, 233, 0.24) 100%)",
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.25)"
        }}>
        <div className="rounded-2xl" style={{
            width: "19rem",
            minWidth: "19rem",
            minHeight: "19rem",
            margin: "2.5rem",
            background: `url(${imgSrc}) lightgray 50% / cover no-repeat`
        }}/>
        <div className="flex flex-col mx-10 h-full" style={{
            minHeight: "19rem",
            margin: "2.5rem"
        }}>
            <span className="text-xl font-normal pt-0 mt-0 mb-4 pb-4">{title}</span>
            <span className="text-lg font-normal pb-4">{text}</span>
            {
                linkTo &&
                <div className="pb-4">
                    <DesignedLink to={linkTo}>
                        <span className="underline">{linkText}</span>
                    </DesignedLink>
                </div>
            }
        </div>
    </div>
}
const allBackgrounds = ['bg-designed-green','bg-designed-orange','bg-designed-light-blue','bg-designed-yellow']
const lightBackgrounds = ['bg-designed-light-blue','bg-designed-yellow']
export function DoubleLineBadge({colorKey,line1,line2}:{colorKey:string,line1:string,line2:string}) {
    const [color,_] = useState(allBackgrounds[hashToNumber(colorKey,allBackgrounds.length)]) // this makes sure project's have consistent colors
    return <div className={classNames("flex flex-col rounded-sm p-1",color,{
        "text-white": lightBackgrounds.includes(color!),
        "text-gray-800": !lightBackgrounds.includes(color!)
    })}>
        <span className="text-xxxs">{line1}</span>
        <span className="text-xxxs font-light">{line2}</span>
    </div>
}

export function DesignedIconButton({icon,title,onClick,disabled,tooltip,stopPropagation}:{icon:ReactElement,title?:ReactNode,onClick:()=>void,disabled:boolean,tooltip:string,stopPropagation?:boolean}) {
    return <Tooltip disabled={tooltip.length==0} label={tooltip}>
        <Button
            style={{
                background: GRADIENT_NAVY
            }}
            data-disabled={disabled}
            size={"compact-sm"}
            radius="xl"
            onClick={(e)=>{
                onClick()
                if(stopPropagation) {
                    e.stopPropagation()
                }
            }}
            className={classNames("m-2")}
            leftSection={title&&icon}
        >{title ?? icon}</Button>
    </Tooltip>
}

export type AdminComboboxOption = {name:string,id:string}
export function AdminCombobox({options,onChange,disabled,initiallySelectedId,label,classNames}:{initiallySelectedId?:string,disabled:boolean,options:Array<AdminComboboxOption>,onChange:(o:AdminComboboxOption)=>void,label?:string,classNames?:string}) {
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
    });
    const [search, setSearch] = useState('');
    const searchWords = search.toLowerCase().trim().split(' ')
    const initiallySelected:AdminComboboxOption|undefined = options.find(o=>o.id==initiallySelectedId)
    const shouldFilterOptions = options.every((p) => p.name !== search);
    const filteredOptions = shouldFilterOptions
        ? options.filter((p) => {
            return searchWords.length == 0 ||
                searchWords.every(w=>p.name.toLowerCase().includes(w))
        })
        : options;
    return <Combobox
        disabled={disabled}
        store={combobox}
        withinPortal={false}
        onOptionSubmit={(val) => {
            onChange(options.find(p=>`${p.name}-${p.id}`==val)!);
            setSearch("");
            combobox.closeDropdown();
        }}
    >
        <Combobox.Target>
            <InputBase
                className={classNames}
                disabled={disabled}
                label={label}
                rightSection={<Combobox.Chevron />}
                value={search}
                onChange={(event) => {
                    combobox.openDropdown();
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                    combobox.selectFirstOption()
                }}
                onClick={() => combobox.openDropdown()}
                onFocus={() => combobox.openDropdown()}
                onBlur={() => {
                    combobox.closeDropdown();
                    setSearch('');
                }}
                placeholder={initiallySelected?.name ?? "Search value"}
                rightSectionPointerEvents="none"
            />
        </Combobox.Target>
        <Combobox.Dropdown>
            <Combobox.Options>
                {filteredOptions.length > 0
                    ? filteredOptions.map((p) => (
                        <Combobox.Option value={`${p.name}-${p.id}`} key={p.id} >
                            {p.name}
                        </Combobox.Option>
                    ))
                    : <Combobox.Empty>Nothing found</Combobox.Empty>}
            </Combobox.Options>
        </Combobox.Dropdown>
    </Combobox>
}


export function DesignedButton({
                                   children,
                                   m0,
                                   opacity,
                                   boxShadow,
                                   disabled,
                                   onClick,
                                   inverse,
                                   submit,
                                   outline,
                                   wFull,
                                   className: cn
                               }: PropsWithChildren<{
    onClick?: () => void,
    m0?: boolean,
    opacity?: boolean,
    boxShadow?: boolean,
    disabled?: boolean,
    submit?: boolean,
    inverse?: boolean,
    outline?: boolean,
    wFull?: boolean,
    className?: string
}>) {
    return <button
        style={{
            background: inverse ? COLOR_WHITE : GRADIENT_NAVY,
            color: inverse ? PRIMARY_BLUE : COLOR_WHITE,
            border: outline ? `1px solid ${PRIMARY_LIGHT_BLUE}` : "none",
            boxShadow: boxShadow ? "0px 0px 50px 0px black" : "none"
        }}
        className={classNames("flex flex-row items-center min-h-12 rounded-2xl py-2 px-8", {
            "w-full": wFull,
            "max-w-fit": !wFull,
            "disabled": disabled,
            "cursor-pointer": !disabled,
            "cursor-not-allowed": disabled,
            "opacity-75": opacity || disabled,
            "m-4": !m0
        }, cn)}
        onClick={disabled ? () => {
        } : onClick}
        disabled={disabled}
        type={submit ? "submit" : "button"}
    >
        {children}
    </button>
}


export function DesignedPlayButton({text, onClick}: { text: string, onClick: () => void }) {
    return <AbsoluteCenterStack>
        <DesignedButton onClick={onClick} opacity boxShadow>
            <span className="text-4xl text-white font-light p-4">{text}</span><IconPlayerPlay strokeWidth={1.5}
                                                                                              size={40}
                                                                                              className="ml-2"/>
        </DesignedButton>
    </AbsoluteCenterStack>

}

export function DesignedLink({children, style, to, inactiveProps, activeProps}: PropsWithChildren<{
    to: string,
    style?: React.CSSProperties,
    inactiveProps?: { style: React.CSSProperties },
    activeProps?: { style: React.CSSProperties }
}>) {
    return <Link to={to}
                 className="cursor-pointer"
                 activeProps={activeProps}
                 inactiveProps={inactiveProps}
                 style={style}>
        {children}
    </Link>
}


export function DesignedPageSection({title, imgSrc, children}: PropsWithChildren<{ title: string, imgSrc?: string }>) {
    return (
        <div className="flex flex-col mb-10 last:mb-0">
            <div className="flex flex-row">
                {imgSrc && <img className="w-14 h-14 mr-10" src={imgSrc}/>}
                <TextXl>{title}</TextXl>
            </div>
            <div className="p-5 bg-neutral-200 even:bg-white even:py-10">{children}</div>
        </div>
    )
}

export function Badge({orange,green, gray, blue,red, wFull, children, classNames: cns}: PropsWithChildren<{
    green?: boolean,
    orange?: boolean,
    blue?: boolean,
    red?: boolean,
    gray?: boolean,
    wFull?: boolean,
    classNames?: string
}>) {
    return <div className={classNames(
        "text-center text-white rounded-2xl text-sm mt-2 py-1 px-3",
        {
            "bg-primary-light-blue": blue,
            "bg-black": !green && !gray && !blue && !red && !orange,
            "bg-success": green,
            "bg-gray-400": gray,
            "bg-red-400": red,
            "bg-orange-400": orange,
            "w-full": wFull
        }
        , cns)}>
        {children}
    </div>
}

export const SuspenseLoader = (
    <AbsoluteCenterStack>
        <TextXl>Loading Your Healthy, Eco-Friendly Home</TextXl>
    </AbsoluteCenterStack>
)

export function BorderlessIconCard({icon, gradientColor, emphasis, text, info}: {
    icon: ReactElement,
    gradientColor: string,
    emphasis: string,
    text: string,
    info?: string
}) {
    return <HStack leftCenter style={{width: "auto"}}>
        <div className="h-20 w-20 p-4 m-12" style={{
            boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.20)",
            borderRadius: "1.25rem",
            background: `linear-gradient(218deg, ${gradientColor} -20.21%, rgba(255, 255, 255, 0.60) 31.21%, rgba(255, 255, 255, 0.60) 56.92%, ${gradientColor} 108.33%)`
        }}>
            <div className="h-12 w-12">{icon}</div>
        </div>
        <div>

            <Text3Xl normal>{emphasis} </Text3Xl>
            {info && <InfoTooltip label={info}/>}
            <br/>
            <TextXl>{text}</TextXl>
        </div>
    </HStack>
}

export function PageTitle({text}: { text: string }) {
    return <div style={{borderBottom: `2px solid ${PRIMARY_LIGHT_BLUE}`, width: "100%", marginBottom: "3rem"}}>
        <Text5Xl>{text}</Text5Xl>
    </div>
}

export function DeleteButton({onClick,isPending}:{onClick:()=>void,isPending:boolean}) {
    return <UnstyledButton
        disabled={isPending}
        className="ml-3 p-2 transition-all rounded-l hover:bg-gray-200"
        onClick={onClick}
    >
        {isPending
            ? <Loader size="1rem" />
            : <IconX size="1rem" />
        }
    </UnstyledButton>
}
export function InfoTooltip({label,center,mx4}:{center?:boolean,mx4?:boolean,label: string | number | true | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<ReactNode> | React.ReactPortal}){
    return <Tooltip
        className={classNames("cursor-pointer p-2 pl-0",{"mx-4":mx4})}
        classNames={{tooltip:classNames("p-4 break-words",{"text-center":center})}}
        multiline
        label={label}
        w={400}
    >
        <IconInfoCircle
            size="2rem"
        />
    </Tooltip>
}
export function EditableCalendar({title,initialValue,startingDayIsoString,shouldDisableDay,save,isPending,isSuccess,alwaysEditing}:{title:string,initialValue:Array<string>,startingDayIsoString?:string,shouldDisableDay:(isoString:string)=>string,save:(dates:Array<string>)=>void,isPending:boolean,isSuccess:boolean,alwaysEditing:boolean}){
    const [editing,setEditing] = useState(alwaysEditing)
    const [dates,setDates] = useState((isoStringArrayToDateArray(initialValue)))
    useEffect(() => {
        if(isSuccess&&!alwaysEditing) {
            setEditing(false)
        }
    }, [isSuccess,alwaysEditing]);
    const untouched = JSON.stringify(dateArrayToIsoStringArray(dates).sort()) == JSON.stringify(initialValue.sort())
    return <HStack wAuto>
        <div>
            <Divider my="sm" label={<TextSm>{title}</TextSm>} labelPosition="center" color={editing?"black":undefined}/>
            <DatePicker
                defaultDate={startingDayIsoString?isoStringToDate(startingDayIsoString):new Date()}
                numberOfColumns={2}
                value={dates}
                onChange={editing?setDates:()=>{}}
                type='multiple'
                weekendDays={[]}
                excludeDate={(d) => !!shouldDisableDay(dateToIsoString(d))}
                renderDay={(d) => {
                    let label = shouldDisableDay(dateToIsoString(d)) ?? ""
                    return <Tooltip disabled={label.length==0} inline label={label}>
                        <div className={classNames("w-full h-full flex flex-col items-center justify-center",{
                            "cursor-not-allowed":!editing,
                            "bg-gray-100":!editing&&!isoStringArrayContainsDate(initialValue,d),
                            "bg-gray-400":!editing&&isoStringArrayContainsDate(initialValue,d)
                        })}>{d.getDate()}</div>
                    </Tooltip>
                }}
            />
        </div>

        <VStack wAuto>
            {!alwaysEditing &&<div><DesignedIconButton
                disabled={isPending}
                icon={editing ? <IconCancel/> : <IconPencil/>}
                title={editing ? "Cancel" : "Edit"}
                onClick={() => {
                    if (editing) {
                        setEditing(false)
                        setDates(isoStringArrayToDateArray(initialValue))
                    } else {
                        setEditing(true)
                    }
                }}
                tooltip=""/></div>}
            {editing && <div><DesignedIconButton
                disabled={isPending || untouched}
                title={"Save"}
                icon={<IconDeviceFloppy/>}
                onClick={() => {
                    save(dateArrayToIsoStringArray(dates))
                }}
                tooltip={untouched ? "Unchanged" : ""}/></div>}
        </VStack>
    </HStack>
}