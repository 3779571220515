import {
    Address,
    Assessment,
    ASSESSMENT_LABEL_BASELINE,
    AssessmentFile,
    AssessmentFilesEnum, ContractorProject, ContractorRecommendation, CostedProformaIncentive,
    HomeAggregate, HUBSPOT_DEAL_PIPELINE_ASSESSMENT, HubspotDeal,
    HubspotLineItemAggregate, HubspotProduct,
    ProformaCost,
    ProformaData,
    ProformaIncentive,
    ProformaItem,
    ProformaPayback,
    ProjectAggregate,
    Recommendation, RecommendationDiscount,
    RecommendationIncentive,
    SeeAirUser
} from "@seeair/schemas";
import dayjs from "dayjs";
import {formatMoney, formatYears} from './util.js';
import {ContractorAssessment, ContractorHome} from "@seeair/schemas";

export function getLatestAssessment(assessments: Array<Assessment>): Assessment | undefined {
    let latest
    for (const assessment of assessments) {
        if (!latest || dayjs(assessment.created_at).isAfter(latest.created_at)) {
            latest = assessment
        }
    }
    return latest
}
const massSaveFullServiceProgramRecNums = ["10","11","12","15","22","30"]
export function shouldShowOtherCompanyDisclaimer(recNumbers:Array<string>):boolean {
    return !recNumbers.every(n=>massSaveFullServiceProgramRecNums.includes(n))
}

export function recStatusToString(r: Recommendation | ContractorRecommendation): string {
    switch (r.status) {
        case 'done':
            return "Done"
        case 'in_progress':
            return "In Progress"
        case 'not_started':
            return "Not Started"
    }
}

export function getRecommendationTitle(r:Recommendation | ContractorRecommendation): string {
    if(IsHomeOwnerRecommendation(r)) {
        return `${r.title}${r.type=="PRO"?"":" (DIY)"}`
    } else {
        return r.title
    }
}

export function buildExtraShortAddress(address: Address) {
    return address.address1 ?? ""
}
export function buildShortAddress(address: Address) {
    return [address.address1, address.city].filter(Boolean).join(' ')
}
export function formatAddressForZillow(address: Address):string {
    return `${address.address1.replace(' ','-')},-${address.city},-${address.state},-${address.postalCode}`
}

export function isHomeOwner(user: SeeAirUser | undefined | null): boolean {
    return !!user && !isContractor(user)
}

export function isSiteAdmin(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "admin"
}

export function isContractor(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "contractor" || user?.role == "contractor-admin"
}

export function isContractorAdmin(user: SeeAirUser | undefined | null): boolean {
    return user?.role == "contractor-admin"
}

function getLowestRecNumberInProject(project: ProjectAggregate | ContractorProject): number {
    return project.recommendations.map(r => parseInt(r.original_rec_id)).sort()[0] ?? 0
}

//first all projects that are in progress, sorted by lowest rec num
//second all recs without a project, sorted by status then rec num
//third all finished projects
//finally ism project

export function recToNum(r: Recommendation | ContractorRecommendation | undefined, projects: Array<ProjectAggregate> | Array<ContractorProject>): number {
    if (!r || !r.original_rec_id) {
        return 0
    }
    const project = projects.find(p => p.project_id == r.project_id)
    let projectModifier:number
    let statusModifier:number
    if(project) {
        const stage = getProjectStage(project)
        projectModifier = getLowestRecNumberInProject(project) - 10000
        if(project.project_type == PROJECT_TYPE_ISM) {
            statusModifier = 500
        } else {
            statusModifier = stage == 'drafting' ? 0 : stage == 'done' ? 200 : -200
        }
    } else {
        projectModifier = 10000
        if(IsHomeOwnerRecommendation(r) && r.type == 'DIY') {
            statusModifier = r.status == 'in_progress' ? 300 : r.status == 'done' ? 400 : 350
        } else {
            statusModifier = r.status == 'in_progress' ? -200 : r.status == 'done' ? 200 : 0
        }

    }
    return parseInt(r.original_rec_id) + statusModifier + projectModifier
}

/*

    Upfront_Cost_Low: z.number(),
    Upfront_Cost_High: z.number(),
    Net_Cost_Low: z.number(),
    Net_Cost_High: z.number(),
    Annual_Savings_Low: z.number(),
    Annual_Savings_High: z.number(),
 */
export function recIncentiveToProformaIncentive(recIncentive: RecommendationIncentive, recommendation: Recommendation): ProformaIncentive {
    let cost: ProformaCost
    if (recIncentive.percentage != null) {
        if (recommendation.rec_data.Upfront_Cost_Low == recommendation.rec_data.Upfront_Cost_High) {
            cost = {
                type: 'single',
                cost: percentageWithCap(recommendation.rec_data.Upfront_Cost_Low, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
                })
            }
        } else {
            cost = {
                type: 'range',
                low: percentageWithCap(recommendation.rec_data.Upfront_Cost_Low, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
                }),
                high: percentageWithCap(recommendation.rec_data.Upfront_Cost_High, {
                    percentage: recIncentive.percentage,
                    cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
                })
            }
        }
    } else {
        if (recIncentive.amountLow == recIncentive.amountHigh) {
            cost = {type: 'single', cost: recIncentive.amountLow!}
        } else {
            cost = {type: 'range', low: recIncentive.amountLow!, high: recIncentive.amountHigh!}
        }
    }
    return {
        title: recIncentive.title,
        cost,
        timeOfPurchase: recIncentive.timeOfPurchase,
        url: recIncentive.url,
        recNumbers: [recommendation.original_rec_id]
    }
}
export function productMatchesRecNumber(product: HubspotProduct|null|undefined, recNumber: string): boolean {
    return (product?.properties?.recommendation_id ?? "").split(',').map(o=>o.trim()).includes(recNumber)
}
export function productMatchesPartNumber(product: HubspotProduct|null|undefined, partNumber: string): boolean {
    return (product?.properties?.seeair_part_number ?? "").split(',').map(o=>o.trim()).includes(partNumber)
}

export function recIncentiveWithLineItemsToProformaIncentive(recIncentive: RecommendationIncentive, recommendation: Recommendation, lineItems: Array<HubspotLineItemAggregate>): ProformaIncentive {
    const cost: number = lineItems.filter(o => productMatchesRecNumber(o.product, recommendation.original_rec_id)).reduce((acc, v) =>
            acc + (recIncentive.discountOnRemainder
                ? parseFloat(v.properties.amount ?? "0") //if discount is on remainder apply against amount which accounts for quantity and discount
                : (parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0"))), // if discount is not on remainder, find the undiscounted total by multiplying price and quantity
        0)

    let incentiveAmount: ProformaCost
    if (recIncentive.percentage != null) {
        incentiveAmount = {
            type: 'single',
            cost: percentageWithCap(cost, {
                percentage: recIncentive.percentage,
                cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
            })
        }
    } else {
        if (recIncentive.amountLow == recIncentive.amountHigh) {
            incentiveAmount = {type: 'single', cost: recIncentive.amountLow!}
        } else {
            incentiveAmount = {type: 'range', low: recIncentive.amountLow!, high: recIncentive.amountHigh!}
        }
    }
    return {
        title: recIncentive.title,
        cost: incentiveAmount,
        timeOfPurchase: recIncentive.timeOfPurchase,
        url: recIncentive.url,
        recNumbers: [recommendation.original_rec_id]
    }
}
// export function recDiscountToProformaIncentive(recIncentive: RecommendationIncentive, recommendation: Recommendation): ProformaIncentive {
//     const cost: number = lineItems.filter(o => productMatchesRecNumber(o.product, recommendation.original_rec_id)).reduce((acc, v) =>
//             acc + (recIncentive.discountOnRemainder
//                 ? parseFloat(v.properties.amount ?? "0") //if discount is on remainder apply against amount which accounts for quantity and discount
//                 : (parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0"))), // if discount is not on remainder, find the undiscounted total by multiplying price and quantity
//         0)
//
//     let incentiveAmount: ProformaCost
//     if (recIncentive.percentage != null) {
//         incentiveAmount = {
//             type: 'single',
//             cost: percentageWithCap(cost, {
//                 percentage: recIncentive.percentage,
//                 cap: recIncentive.cap ?? Number.MAX_SAFE_INTEGER
//             })
//         }
//     } else {
//         if (recIncentive.amountLow == recIncentive.amountHigh) {
//             incentiveAmount = {type: 'single', cost: recIncentive.amountLow!}
//         } else {
//             incentiveAmount = {type: 'range', low: recIncentive.amountLow!, high: recIncentive.amountHigh!}
//         }
//     }
//     return {
//         title: recIncentive.title,
//         cost: incentiveAmount,
//         timeOfPurchase: recIncentive.timeOfPurchase,
//         url: recIncentive.url,
//         recNumbers: [recommendation.original_rec_id]
//     }
// }
export function recHasLineItems(recommendation: Recommendation): boolean {
    return Object.values(recommendation.line_items ?? {}).length > 0
}
export function recDataToProformaData(recommendation: Recommendation): ProformaData {
    const rec_data = recommendation.rec_data
    const differenceInNetAndUpfrontCost = rec_data.Net_Cost_Low != null &&
        rec_data.Upfront_Cost_Low != null &&
        rec_data.Net_Cost_High != null &&
        rec_data.Upfront_Cost_High &&
        (rec_data.Net_Cost_Low < rec_data.Upfront_Cost_Low || rec_data.Net_Cost_High < rec_data.Upfront_Cost_High)
    let incentives: Array<ProformaIncentive>
    if (recommendation.discounts && Object.values(recommendation.discounts).length > 0) {
        incentives = Object.values(recommendation.discounts).map(o => recIncentiveToProformaIncentive(o, recommendation))
    } else {
        if (differenceInNetAndUpfrontCost) {
            incentives = [{
                title: "Aggregated", //TODO perhaps render this specially
                cost: toProformaCost([rec_data.Upfront_Cost_Low - rec_data.Net_Cost_Low!, rec_data.Upfront_Cost_High - rec_data.Net_Cost_High!]),
                timeOfPurchase: false,
                recNumbers: [recommendation.original_rec_id]
            }]
        } else {
            incentives = []
        }
    }
    return {
        lineItems: [],
        gross: toProformaCost([rec_data.Upfront_Cost_Low, rec_data.Upfront_Cost_High]),
        incentives,
        annual_savings: toProformaCost([rec_data.Annual_Savings_Low, rec_data.Annual_Savings_High])
    }
}

function lineItemToProformaItem(item: HubspotLineItemAggregate, recs: Array<Recommendation>): ProformaItem {
    const potentialRecIds = (item.product?.properties?.recommendation_id?.split(',') ?? []).map(o=>o.trim())
    const recNumbers = recs
        .filter(r => potentialRecIds.includes(r.original_rec_id))
        .map(r => r.original_rec_id)
    return {
        title: item.properties.name ?? "",
        price_per_unit: parseFloat(item.properties.price ?? "0"),
        quantity: parseFloat(item.properties.quantity ?? "0"),
        recNumbers
    }
}

export function hubspotLineItemsToProformaData(lineItems: Array<HubspotLineItemAggregate>, recommendations: Array<Recommendation>): ProformaData {
    const sumAnnualSavingsHigh = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_High, 0)
    const sumAnnualSavingsLow = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_Low, 0)
    const undiscountedLineItemSum = lineItems.reduce((acc, v) =>
            acc + (parseFloat(v.properties.quantity ?? "0") * parseFloat(v.properties.price ?? "0")),
        0)
    const incentives = recommendations
        .flatMap(r => Object.values(r.discounts ?? {})
            .map(i => recIncentiveWithLineItemsToProformaIncentive(i, r, lineItems))
            ?.filter(i => proformaCostGreaterThenZero(i.cost)) ?? [])
    return {
        lineItems: lineItems.map(o => lineItemToProformaItem(o, recommendations)),
        incentives,
        annual_savings: toProformaCost([sumAnnualSavingsLow, sumAnnualSavingsHigh]),
        gross: {type: "single", cost: undiscountedLineItemSum}
    }
}
export function recommendationLineItemsToProformaData(recommendations: Array<Recommendation>): ProformaData {
    const sumAnnualSavingsHigh = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_High, 0)
    const sumAnnualSavingsLow = recommendations.reduce((acc, v) => acc + v.rec_data.Annual_Savings_Low, 0)

    const lineItemArray = recommendations.flatMap(r=>Object.values(r.line_items ?? {}))
    const undiscountedLineItemSum = lineItemArray.reduce((acc, v) =>
            acc + (v.quantity * v.price_per_unit),
        0)

    const lineItems:Array<ProformaItem> = []
    const incentives:Array<ProformaIncentive> = []
    const remainderDiscounts:{[id:string]:RecommendationDiscount}={}
    const remainderByRec:{[id:string]:number}={}
    for(const r of recommendations){
        const lineItemsForRec:Array<ProformaItem> = Object.values(r.line_items ?? {}).map(li=>{
            const mappedItem = {
                price_per_unit:li.price_per_unit,
                quantity:li.quantity,
                recNumbers:[r.original_rec_id],
                title:li.name
            }
            return mappedItem
            //TODO handle line item specific discounts
        })
        const recLineItemSum = lineItemsForRec.reduce((acc, v) =>
                acc + (v.quantity * v.price_per_unit),
            0)
        const discountsArray = Object.values(r.discounts ?? {})
            .sort((d1,d2)=>d1.order-d2.order)
        const incentivesForRec:Array<CostedProformaIncentive> = []
        for(const d of discountsArray) {
            if(d.discountOnRemainder) {
                //put this in a map to dedupe (so that caps are correctly calculated)
                remainderDiscounts[d.incentive_id] = d
            } else {
                const cost:ProformaCost = {type:'single',cost:percentageWithCap(recLineItemSum,d)}
                const mappedDiscount:CostedProformaIncentive = {
                    title:d.title,
                    url:d.url,
                    cost,
                    timeOfPurchase:d.timeOfPurchase,
                    recNumbers:[r.original_rec_id]
                }
                incentivesForRec.push(mappedDiscount)
            }
        }
        incentives.push(...incentivesForRec)
        lineItems.push(...lineItemsForRec)
        //apply discounts to the total, not by order (we can figure that out later if it matters)
        remainderByRec[r.original_rec_id]= incentivesForRec.reduce((acc,v)=>acc-v.cost.cost,recLineItemSum)
    }
    for(const d of Object.values(remainderDiscounts)) {
        let recsSum = 0
        const recNumbers:Array<string> = []
        for(const rn of d.recNums) {
            if(remainderByRec[rn]) {
                recsSum += remainderByRec[rn]
                recNumbers.push(rn)
            }
        }
        const cost:ProformaCost = {type:'single',cost:percentageWithCap(recsSum,d)}
        const mappedDiscount:ProformaIncentive = {
            title:d.title,
            url:d.url,
            cost,
            timeOfPurchase:d.timeOfPurchase,
            recNumbers
        }
        incentives.push(mappedDiscount)
    }
    //apply remainder style discounts all at the end
    return {
        lineItems,
        incentives,
        annual_savings: toProformaCost([sumAnnualSavingsLow, sumAnnualSavingsHigh]),
        gross: {type: "single", cost: undiscountedLineItemSum}
    }
}

export function isProformaPaybackFiniteAndNotNegative(payback: ProformaPayback): boolean {
    if (payback.type == 'single') {
        return isFinite(payback.years) && payback.years >= 0
    }
    return isFinite(payback.high) && payback.high >= 0 && isFinite(payback.low) && payback.low >= 0
}

export function proformaCostGreaterThenZero(cost: ProformaCost): boolean {
    if (cost.type == 'single') {
        return cost.cost > 0
    } else {
        return cost.high > 0
    }
}

export function getRecNumbersForProject(project: ProjectAggregate) {
    return project.recommendations.map(r => r.original_rec_id).join(",")
}

export function toProformaCost([low, high]: [number, number]): ProformaCost {
    if (low == high) {
        return {type: 'single', cost: low}
    } else if (low < high) {
        return {type: 'range', low, high}
    } else {
        return {type: "range", low: high, high: low}
    }
}

export function getLatestFinishedAssessment(home: HomeAggregate): Assessment | 'not_found' {
    const finishedAssessments = home.assessments.filter(a => a.assessment_status == 'done' || a.assessment_status == 'pending_homeowner_review')
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]!
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(a => a.assessment_label != ASSESSMENT_LABEL_BASELINE)
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]!
        } else {
            return finishedAssessments[0]!
        }
    }
    return 'not_found'
}
export function getLatestFinishedContractorAssessment(home: ContractorHome): ContractorAssessment | 'not_found' {
    const finishedAssessments = home.assessments.filter(a => a.assessment_status == 'done' || a.assessment_status == 'pending_homeowner_review')
    if (finishedAssessments.length == 1) {
        return finishedAssessments[0]!
    } else if (finishedAssessments.length > 1) {
        const followUpAssessments = finishedAssessments.filter(a => a.assessment_label != ASSESSMENT_LABEL_BASELINE)
        if (followUpAssessments.length > 0) {
            return followUpAssessments[0]!
        } else {
            return finishedAssessments[0]!
        }
    }
    return 'not_found'
}

export function getFileVersionsSortedByLatest(files: Array<AssessmentFile>): Array<AssessmentFile> {
    return [...files].sort((a, b) =>
        dayjs(a.created_date).unix() - dayjs(b.created_date).unix())
        .reverse()
}

export function getLatestFile(assessment: ContractorAssessment, file: AssessmentFilesEnum): AssessmentFile | 'not_found' {
    const files = Object.values(assessment.assessment_files_list ?? {}).filter(f=>f.type == file)
    return getFileVersionsSortedByLatest(files)[0] ?? 'not_found'
}

export function formatMoneyRange(cost: ProformaCost): string {
    if (cost.type == 'single') {
        return formatMoney(cost.cost)
    }
    return `${formatMoney(cost.low)} to ${formatMoney(cost.high)}`
}

export function formatYearsRange(payback: ProformaPayback): string {
    if (payback.type == "single") {
        return formatYears(payback.years)
    }
    return `${formatYears(payback.low)} to ${formatYears(payback.high)}`
}

function percentageWithCap(cost: number, {percentage, cap}: { percentage: number, cap: number }): number {
    return Math.min(cap, cost * percentage)
}

function applyIncentive(cost: number, incentive: RecommendationIncentive, lowOrHigh: keyof Pick<RecommendationIncentive, 'amountHigh' | 'amountLow'>): number {
    if (incentive.percentage != null) {
        return percentageWithCap(cost, {
            percentage: incentive.percentage,
            cap: incentive.cap ?? Number.MAX_SAFE_INTEGER
        })
    } else {
        return incentive[lowOrHigh] ?? 0
    }
}

export function calculateNetCost(data: ProformaData, includeRebates: boolean): ProformaCost {
    const sumOfIncentivesLow = data.incentives
        .filter(o => includeRebates || o.timeOfPurchase)
        .reduce(
            (acc, {cost}) =>
                acc + (cost.type == 'range'
                    ? cost.low
                    : cost.cost)
            , 0)
    const sumOfIncentivesHigh = data.incentives
        .filter(o => includeRebates || o.timeOfPurchase)
        .reduce(
            (acc, {cost}) =>
                acc + (cost.type == 'range'
                    ? cost.high
                    : cost.cost)
            , 0)

    if (sumOfIncentivesHigh == sumOfIncentivesLow && data.gross.type == 'single') {
        return {type: 'single', cost: data.gross.cost - sumOfIncentivesLow}
    } else {
        return {
            type: 'range',
            high: (data.gross.type == 'range' ? data.gross.high : data.gross.cost) - sumOfIncentivesHigh,
            low: (data.gross.type == 'range' ? data.gross.low : data.gross.cost) - sumOfIncentivesLow
        }
    }

}

export function calculatePaybackPeriod(data: ProformaData): ProformaPayback {
    // console.log(`calculate payback: ${JSON.stringify(data)}`)
    const annualSavingAvg = data.annual_savings.type == 'range'
        ? ((data.annual_savings.high + data.annual_savings.low) / 2)
        : data.annual_savings.cost
    const net = calculateNetCost(data, true)
    // console.log(`annualSavingsAvg: ${annualSavingAvg}`)
    // console.log(`net: ${JSON.stringify(net)}`)
    if(net.type == "single") {
        const paybackResponse:ProformaPayback = {type: 'single', years: net.cost / annualSavingAvg}
        // console.log(`paybackResponse: ${JSON.stringify(paybackResponse)}`)
        return paybackResponse
    } else {
        const paybackResponse:ProformaPayback = {type: 'range', low: net.low / annualSavingAvg, high: net.high / annualSavingAvg}
        // console.log(`paybackResponse: ${JSON.stringify(paybackResponse)}`)
        return paybackResponse
    }
}

export const HS_DEAL_DONE = 'closedwon'
export const HS_DEAL_IN_PROGRESS = 'presentationscheduled'
export const HS_PAYMENT_AWAITING = 'PENDING'
export const HS_PAYMENT_PAID = 'PAID'
export const HS_SIGNED = 'APPROVAL_NOT_NEEDED'

export type ProjectStage = 'drafting' | 'quoted' | 'done'
export function getProjectStage(project: ProjectAggregate | ContractorProject): ProjectStage {
    if(project.project_type == PROJECT_TYPE_ISM) {
        return "done"
    }
    if (IsHomeOwnerProject(project) && project.hubspot_quote_payment_status == HS_PAYMENT_PAID) {
        return "done"
    }
    if (IsHomeOwnerProject(project) && project.hubspot_quote_amount != null) {
        return "quoted"
    }
    return 'drafting'

}


export function getProjectStageString(stage: ProjectStage): string {
    switch (stage) {
        case "drafting":
            return "Drafting"
        case "quoted":
            return "Quoted"
        default:
            return "Done"
    }
}


export function isAssessmentDeal(deal:HubspotDeal): boolean {
    return deal.properties.pipeline == HUBSPOT_DEAL_PIPELINE_ASSESSMENT
}

export function IsHomeAggregate(h:HomeAggregate | ContractorHome):h is HomeAggregate {
    return Object.keys(h).includes("recommendations")
}
export function IsHomeOwnerRecommendation(r:Recommendation | ContractorRecommendation):r is Recommendation {
    return Object.keys(r).includes("type")
}
export function IsHomeOwnerProject(p:ProjectAggregate | ContractorProject):p is ProjectAggregate {
    return Object.keys(p).includes("hubspot_quote_payment_status")
}

export const PROJECT_TYPE_ISM = "ISM"
export const PROJECT_TYPE_SEEAIR = "SEEAIR"