import {
    ElectrificationData,
    HomeDetails,
    NormalizedEnergyUsage, NormalizedEnergyUsageMonth,
    UtilityAgeEnum
} from "@seeair/schemas";

import dayjs from "dayjs";
import {
    calculateBaseloadUsage,
    hasPrimaryGasHeat,
    hasPrimaryOilHeat,
    isWinterMonth,
    thermsToKwh
} from './buildingScience.js';



const rvalue_lookup = [
    {Location: "MA", Year: 1960, Material: "Wall", Rvalue: 3},
    {Location: "MA", Year: 1970, Material: "Wall", Rvalue: 3},
    {Location: "MA", Year: 1980, Material: "Wall", Rvalue: 7},
    {Location: "MA", Year: 1990, Material: "Wall", Rvalue: 7},
    {Location: "MA", Year: 2000, Material: "Wall", Rvalue: 7},
    {Location: "MA", Year: 2010, Material: "Wall", Rvalue: 13},
    {Location: "MA", Year: 2020, Material: "Wall", Rvalue: 15},
    {Location: "CA", Year: 1960, Material: "Wall", Rvalue: 3},
    {Location: "CA", Year: 1970, Material: "Wall", Rvalue: 11},
    {Location: "CA", Year: 1980, Material: "Wall", Rvalue: 11},
    {Location: "CA", Year: 1990, Material: "Wall", Rvalue: 11},
    {Location: "CA", Year: 2000, Material: "Wall", Rvalue: 11},
    {Location: "CA", Year: 2010, Material: "Wall", Rvalue: 13},
    {Location: "CA", Year: 2020, Material: "Wall", Rvalue: 25},
    {Location: "MA", Year: 1960, Material: "Window", Rvalue: 1.2},
    {Location: "MA", Year: 1970, Material: "Window", Rvalue: 1.2},
    {Location: "MA", Year: 1980, Material: "Window", Rvalue: 2},
    {Location: "MA", Year: 1990, Material: "Window", Rvalue: 2},
    {Location: "MA", Year: 2000, Material: "Window", Rvalue: 2},
    {Location: "MA", Year: 2010, Material: "Window", Rvalue: 2},
    {Location: "MA", Year: 2020, Material: "Window", Rvalue: 2},
    {Location: "CA", Year: 1960, Material: "Window", Rvalue: 0.9},
    {Location: "CA", Year: 1970, Material: "Window", Rvalue: 0.9},
    {Location: "CA", Year: 1980, Material: "Window", Rvalue: 1.7},
    {Location: "CA", Year: 1990, Material: "Window", Rvalue: 1.7},
    {Location: "CA", Year: 2000, Material: "Window", Rvalue: 1.7},
    {Location: "CA", Year: 2010, Material: "Window", Rvalue: 1.7},
    {Location: "CA", Year: 2020, Material: "Window", Rvalue: 1.7},
    {Location: "MA", Year: 1960, Material: "Attic", Rvalue: 19},
    {Location: "MA", Year: 1970, Material: "Attic", Rvalue: 19},
    {Location: "MA", Year: 1980, Material: "Attic", Rvalue: 30},
    {Location: "MA", Year: 1990, Material: "Attic", Rvalue: 38},
    {Location: "MA", Year: 2000, Material: "Attic", Rvalue: 38},
    {Location: "MA", Year: 2010, Material: "Attic", Rvalue: 38},
    {Location: "MA", Year: 2020, Material: "Attic", Rvalue: 38},
    {Location: "CA", Year: 1960, Material: "Attic", Rvalue: 19},
    {Location: "CA", Year: 1970, Material: "Attic", Rvalue: 19},
    {Location: "CA", Year: 1980, Material: "Attic", Rvalue: 30},
    {Location: "CA", Year: 1990, Material: "Attic", Rvalue: 38},
    {Location: "CA", Year: 2000, Material: "Attic", Rvalue: 38},
    {Location: "CA", Year: 2010, Material: "Attic", Rvalue: 38},
    {Location: "CA", Year: 2020, Material: "Attic", Rvalue: 38},
    {Location: "MA", Year: 1960, Material: "Floor", Rvalue: 4},
    {Location: "MA", Year: 1970, Material: "Floor", Rvalue: 4},
    {Location: "MA", Year: 1980, Material: "Floor", Rvalue: 11},
    {Location: "MA", Year: 1990, Material: "Floor", Rvalue: 30},
    {Location: "MA", Year: 2000, Material: "Floor", Rvalue: 30},
    {Location: "MA", Year: 2010, Material: "Floor", Rvalue: 30},
    {Location: "MA", Year: 2020, Material: "Floor", Rvalue: 30},
    {Location: "CA", Year: 1960, Material: "Floor", Rvalue: 4},
    {Location: "CA", Year: 1970, Material: "Floor", Rvalue: 4},
    {Location: "CA", Year: 1980, Material: "Floor", Rvalue: 11},
    {Location: "CA", Year: 1990, Material: "Floor", Rvalue: 30},
    {Location: "CA", Year: 2000, Material: "Floor", Rvalue: 30},
    {Location: "CA", Year: 2010, Material: "Floor", Rvalue: 30},
    {Location: "CA", Year: 2020, Material: "Floor", Rvalue: 30}
];

function rValueLookup(homeDetails: HomeDetails, location_id: string) {
    const year_built = homeDetails.yearBuilt!;
    const state = location_id;
    const floors = homeDetails.floors!;

    let rvalue_subset;

    // Handle year_built before 1960
    if (year_built < 1960) {
        rvalue_subset = rvalue_lookup.filter(row => row.Location === state && row.Year === 1960);
    } else {
        rvalue_subset = rvalue_lookup.filter(row => row.Location === state && row.Year < year_built && row.Year > (year_built - 10));
    }

    // Calculate U-value (inverse of R-value)
    rvalue_subset = rvalue_subset.map(row => ({
        ...row,
        Uvalue: 1 / row.Rvalue
    }));

    // Define area based on material type
    const area_data = [
        {Material: 'Attic', Area: homeDetails.sqft! / floors},
        {Material: 'Floor', Area: homeDetails.sqft!},
        {Material: 'Wall', Area: 8 * 4.1 * Math.sqrt(homeDetails.sqft!) * 0.7},
        {Material: 'Window', Area: 8 * 4.1 * Math.sqrt(homeDetails.sqft!) * 0.3}
    ];

    // Merge area_data with rvalue_subset
    rvalue_subset = rvalue_subset.map(row => {
        const areaRow = area_data.find(area => area.Material === row.Material);
        // Add an Area field if it exists, otherwise set Area to 0
        return areaRow ? {...row, Area: areaRow.Area} : {...row, Area: 0};
    });

    // Calculate weighted U-value
    rvalue_subset = rvalue_subset.map(row => ({
        ...row,
        UvalueA: row.Uvalue * row.Area
    }));

    // Compute the overall R-value
    const totalArea = rvalue_subset.reduce((sum, row) => sum + row.Area, 0);
    const totalUvalueA = rvalue_subset.reduce((sum, row) => sum + row.UvalueA, 0);

    return totalArea / totalUvalueA;
}

const heating_afue_lookup: Record<UtilityAgeEnum, number> = {
    "<5 Years": 95,
    "5-10 Years": 90,
    "10-15 Years": 85,
    "15-20 Years": 80,
    ">20 Years": 75,
    "Not Applicable": 0
}


export function deriveElectrificationData(
    homeDetails: HomeDetails,
    energyUsage: NormalizedEnergyUsage): ElectrificationData {
    // console.log(`generating ElectrificationData: ${JSON.stringify(energyUsage, null, 2)}`)
    // data from assessment
    const location_id = "MA"
    const usageArray:Array<NormalizedEnergyUsageMonth> = Object.values(energyUsage).map(u=>({
        electric_usage:u.electric_usage,
        electric_cost:u.electric_cost,
        gas_usage:thermsToKwh(u.gas_usage),
        gas_cost:u.gas_cost,
        oil_usage:thermsToKwh(u.oil_usage),
        oil_cost:u.oil_cost,
        date:u.date
    }))
    const last12 = [...usageArray].sort((a,b)=>a.date-b.date)
      .slice(-13) //get last 13
      .slice(0,12) // ignore latest incase we only have partial data
    // console.log(`last12: ${JSON.stringify(last12.map(o=>dayjs.unix(o.date).format("YYYY-MM-DD")))}`)
    // console.log(`last12: ${dayjs.unix(last12[0]!.date).format('YYYY-MM-DD')} to ${dayjs.unix(last12[last12.length-1]!.date).format('YYYY-MM-DD')} (${last12.length})`)


    const heating_load = location_id == "MA" ? 25 : 16;
    const cooling_load = 18;
    const heating_radiation = location_id == "MA" ? heating_load * 0.05 : heating_load * 0.01;
    const cooling_radiation = cooling_load * 0.35;
    const yearBuilt = homeDetails.yearBuilt!;

    const aer = yearBuilt < 1940 ? 1.3 : yearBuilt < 1980 ? 0.8 : 0.5;
    const rValue = rValueLookup(homeDetails, location_id);
    const sqft = homeDetails.sqft!;
    const heating_afue = homeDetails.primary_heating == "Gas boiler"
        ? heating_afue_lookup[homeDetails.primary_heating_age!]
        : 0

    let primaryHeatingBtus = 0;
    if ((homeDetails.primary_heating == "Central gas furnace" || homeDetails.primary_heating == "Gas boiler")
        && (!homeDetails.primary_heating_btus || homeDetails.primary_heating_btus == 0)) {
        primaryHeatingBtus = 42 * sqft;
    }
    let primaryCoolingBtus = 0;
    if (homeDetails.primary_cooling != "None" && (!homeDetails.primary_cooling_btus || homeDetails.primary_cooling_btus == 0)) {
        primaryCoolingBtus = 31 * sqft;
    }

    const heating_infiltration = (heating_load - heating_radiation) * (0.5 - (1 - Math.min(aer / 2, 1) - Math.min(rValue / 20, 1)) / 4);
    const cooling_infiltration = (cooling_load - cooling_radiation) * (0.5 - (1 - Math.min(aer / 2, 1) - Math.min(rValue / 20, 1)) / 4);
    const heating_insulation = heating_load - heating_radiation - heating_infiltration;
    const cooling_insulation = cooling_load - cooling_radiation - cooling_infiltration;
    // Since we live in a heating climate, it takes much more heat to keep the house warm
    const heating_load_current = (primaryHeatingBtus) * heating_afue / (100 * sqft);
    const cooling_load_current = (primaryCoolingBtus) / sqft;

    // creating hvac_sizing object
    const hvac_sizing: ElectrificationData['hvac_sizing'] = {
        heating_load,
        cooling_load,
        heating_radiation,
        cooling_radiation,
        heating_infiltration,
        cooling_infiltration,
        heating_insulation,
        cooling_insulation,
        heating_load_current,
        cooling_load_current,
        heating_afue
    }

    const baseload_electricity_usage = calculateBaseloadUsage(last12,'electric_usage')
    const baseload_oil_usage = calculateBaseloadUsage(last12,'oil_usage')
    const baseload_gas_usage = calculateBaseloadUsage(last12,'gas_usage')
    const primaryGasHeat = hasPrimaryGasHeat(homeDetails)
    const primaryOilHeat = hasPrimaryOilHeat(homeDetails)
    const noPrimaryCooling = homeDetails.primary_cooling == "None"
    // Calculate Baseload, Heating, and Cooling usage for each date
    const energy_chart = usageArray.map(usage => {
        const d = dayjs.unix(usage.date)
        const Date = usage.date
        let Other_Usage: number
        let Heating_Usage: number
        let Cooling_Usage: number
        let Other_Cost: number
        let Heating_Cost: number
        let Cooling_Cost: number
        if(noPrimaryCooling) {
            Cooling_Cost = 0
            Cooling_Usage = 0
        } else {
            if (isWinterMonth(d,'MA')) { //typically cool may to september
                Cooling_Cost = 0
                Cooling_Usage = 0
            } else {
                // console.log(`cooling for: ${d} - electric cost: ${usage.electric_cost} electric usage: ${usage.electric_usage} electric baseload: ${baseload_electricity_usage}`)
                if(usage.electric_usage > baseload_electricity_usage) {
                    Cooling_Cost = usage.electric_cost - (usage.electric_cost/usage.electric_usage * baseload_electricity_usage)
                    Cooling_Usage = usage.electric_usage - baseload_electricity_usage
                } else {
                    Cooling_Cost = 0
                    Cooling_Usage = 0
                }
            }
        }
        if (isWinterMonth(d,'MA')) { //typically heat before may and after september
            if(primaryGasHeat) {
                // console.log(`heating for: ${d} - gas cost: ${usage.gas_cost} gas usage: ${usage.gas_usage} gas baseload: ${baseload_gas_usage}`)
                Heating_Cost = usage.gas_cost - (usage.gas_cost/usage.gas_usage * baseload_gas_usage)
                Heating_Usage = usage.gas_usage - baseload_gas_usage
            } else if(primaryOilHeat) {
                Heating_Cost = usage.oil_cost - (usage.oil_cost/usage.oil_usage * baseload_oil_usage)
                Heating_Usage = usage.oil_usage - baseload_oil_usage
            } else {
                Heating_Cost = usage.electric_cost - (usage.electric_cost/usage.electric_usage * baseload_electricity_usage)
                Heating_Usage = usage.electric_usage - baseload_electricity_usage
            }
        } else {
            // if(primaryGasHeat) {
            //     console.log(`heating for: ${d} - gas cost: ${usage.gas_cost} gas usage: ${usage.gas_usage} gas baseload: ${baseload_gas_usage}`)
            //     Heating_Cost = usage.gas_cost - (usage.gas_cost/usage.gas_usage * baseload_gas_usage)
            //     Heating_Usage = usage.gas_usage - baseload_gas_usage
            // } else if(primaryOilHeat) {
            //     Heating_Cost = usage.oil_cost - (usage.oil_cost/usage.oil_usage * baseload_oil_usage)
            //     Heating_Usage = usage.oil_usage - baseload_oil_usage
            // } else {
            //     Heating_Cost = 0
            //     Heating_Usage = 0
            // }

            Heating_Cost = 0
            Heating_Usage = 0
        }
        const totalUsage = usage.electric_usage + (usage.gas_usage ?? 0) + (usage.oil_usage ?? 0)
        const totalCost = usage.electric_cost + (usage.gas_cost ?? 0) + (usage.oil_cost ?? 0)
        // Calculate Baseload usage as total electric usage minus heating and cooling usage
        Other_Usage = totalUsage - Heating_Usage - Cooling_Usage
        Other_Cost = totalCost - Heating_Cost - Cooling_Cost
        return {
            Date,
            Other_Usage,
            Heating_Usage,
            Cooling_Usage,
            Other_Cost,
            Heating_Cost,
            Cooling_Cost
        };
    });
    const gasCostAnnual = last12.reduce((acc, v) => acc + (v.gas_cost ?? 0), 0);
    const electricCostAnnual = last12.reduce((acc, v) => acc + v.electric_cost, 0);
    const oilCostAnnual = last12.reduce((acc, v) => acc + (v.oil_cost ?? 0), 0);
    const gasUsageAnnual = last12.reduce((acc, v) => acc + (v.gas_usage ?? 0), 0)
    const electricUsageAnnual = last12.reduce((acc, v) => acc + v.electric_usage, 0);
    const oilUsageAnnual = last12.reduce((acc, v) => acc + (v.oil_usage ?? 0), 0)
    // console.log(`deriveElectrificationData: primaryGasHeat:${primaryGasHeat} primaryOilHeat:${primaryOilHeat} noPrimaryCooling:${noPrimaryCooling}`)
    return {
        energy_use: {
            Gas: gasUsageAnnual,
            Electric: electricUsageAnnual,
            Oil: oilUsageAnnual
        },
        energy_cost: {
            Gas: gasCostAnnual,
            Electric: electricCostAnnual,
            Oil: oilCostAnnual,
        },
        energy_chart: energy_chart,
        hvac_sizing: hvac_sizing,
        avg_electricity_cost: electricCostAnnual/electricUsageAnnual,
        avg_oil_cost: oilUsageAnnual == 0 ? 0 : oilCostAnnual/oilUsageAnnual,
        avg_gas_cost: gasUsageAnnual == 0 ? 0 : gasCostAnnual/gasUsageAnnual,
        baseload_electricity_usage,
        baseload_gas_usage,
        baseload_oil_usage
    }
}


