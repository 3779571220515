import {
    HDivider,
    HStack,
    ProjectIcons, TextLinkSm,
    TextSm, TextXl, TextXs, TextXsItalic,
    VStack
} from "./DesignBase.js";
import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames";
import { ProformaData, ProformaIncentive, ProformaItem} from "@seeair/schemas";
import {
    calculateNetCost,
    calculatePaybackPeriod,
    formatMoney,
    formatMoneyRange,
    formatQuantity,
    formatYearsRange, isProformaPaybackFiniteAndNotNegative,
} from "@seeair/shared";
import { InfoTooltip } from "./DesignComponents.js";

const PROFORMA_LINE_ITEM_DIVIDERS = "border-b border-r-0 border-l-0 border-t-0 border-solid border-slate-200 last-of-type:border-b-0 min-h-12"
function ProformaText({children, indent, bold, url, icon,align,info}: PropsWithChildren<{
    indent?: boolean,
    bold?: boolean,
    url?: string,
    icon?: ReactElement,
    align?: 'left'|'right',
    info?: string
}>) {
    let component: ReactElement
    if (url) {
        component = <a href={url} target="_blank" rel="noreferrer">
            <TextLinkSm light m0 classNames={classNames("leading-8", {

                "font-semibold": bold
            })}>{children}</TextLinkSm>
        </a>
    } else {
        component = <TextSm light m0 classNames={classNames("leading-8", {})}>{children}</TextSm>
    }
    let tooltip:ReactElement | null = null
    if(info) {
        tooltip=<InfoTooltip mx4 center
                             label={info}/>
    }
    return <HStack leftCenter shrink classNames={classNames({
        // "ml-8": indent,
        "w-1/3":align=='right',
        "justify-end": align=='right',
        "pr-4": align=='right',
        "justify-start": align=='left',
        "w-2/3": align=='left',
    })}>
        <span className="mr-4">{icon}</span>{component}{tooltip}
    </HStack>
}
function ProformaItemLine({item}:{item:ProformaItem}) {
    return <HStack between classNames={PROFORMA_LINE_ITEM_DIVIDERS}>
        <ProformaText align={"left"} indent icon={<ProjectIcons sm rec_numbers={item.recNumbers}/>}>{item.title}</ProformaText>
        <ProformaText align={"right"}>
            <span className="text-left inline-block">{formatQuantity(item.quantity)}</span>
            <span className="text-center inline-block">&nbsp;&nbsp;x&nbsp;&nbsp;</span>
            <span className="text-left inline-block">{formatMoney(item.price_per_unit)}</span>
            <span className="text-center inline-block">&nbsp;&nbsp;=&nbsp;&nbsp;</span>
            <span
                className="w-16 text-right inline-block">{formatMoney(item.quantity * item.price_per_unit)}</span>
        </ProformaText>
    </HStack>
}
export function Proforma({title, data, finePrint}: {
    title: string,
    finePrint?: Array<string>,
    data: ProformaData
}) {
    const hasIncentives = data.incentives.length > 0
    const hasLineItems = data.lineItems.length > 0
    return <VStack classNames="px-4 pb-8 sm:pb-16 bg-white">
        <TextXl medium center>{title}</TextXl>
        {hasLineItems && <VStack>
            <ProformaText>Line Items</ProformaText>
            <div>
                {data.lineItems.map((item, i) => <ProformaItemLine item={item} key={i} />)}
            </div>
        </VStack>}
        <HDivider/>
        <HStack between>
            <ProformaText align={"left"}>{hasLineItems && "Total "}Cost{hasIncentives && " Before Incentives or Rebates"}</ProformaText>
            <ProformaText align={"right"}>{formatMoneyRange(data.gross)}</ProformaText>
        </HStack>
        {hasIncentives && <ProformaIncentiveSection data={data}/>
        }
        <ProformaPaybackSection data={data}/>
        <VStack>
            {(finePrint ?? []).map((fp) => (
                <TextXsItalic key={fp} classNames="mt-8">{fp}</TextXsItalic>
            ))}
        </VStack>
    </VStack>
}


function ProformaIncentiveLine({inc}: { inc: ProformaIncentive }) {
    return <HStack between classNames={PROFORMA_LINE_ITEM_DIVIDERS}>
        <ProformaText align={"left"} indent url={inc.url} icon={<ProjectIcons sm rec_numbers={inc.recNumbers}/>}>
            {inc.title}
            {inc.description && <TextXs>&nbsp;({inc.description})</TextXs>}</ProformaText>
        <ProformaText align={"right"}>
            {
                formatMoneyRange(inc.cost)
            }
        </ProformaText>
    </HStack>
}

function ProformaIncentiveSection({data}: { data: ProformaData }) {
    const immediateIncentives = data.incentives.filter(inc => inc.timeOfPurchase)
    const rebateIncentives = data.incentives.filter(inc => !inc.timeOfPurchase)
    return <VStack>
        <HDivider/>
        {
            immediateIncentives.length > 0 && <React.Fragment>
                <HStack>
                    <ProformaText info="These are deducted from your purchase price today.">Incentives</ProformaText>
                </HStack>
                {
                    immediateIncentives.map((inc, i) => <ProformaIncentiveLine inc={inc} key={i}/>)
                }
            </React.Fragment>
        }
        {
            rebateIncentives.length > 0 && <React.Fragment>
                <HStack>
                    <ProformaText
                        info="* Tax credits are estimates and may vary depending on your tax situation. We'll help you apply for these rebates and show you the appropriate tax forms so you can get paid back later.">
                        Rebates & Tax Credits
                    </ProformaText>
                    </HStack>

                <div>
                    {rebateIncentives.map((inc, i) => <ProformaIncentiveLine inc={inc} key={i}/>)}
                </div>
            </React.Fragment>
        }
        <HDivider/>
        <HStack between>
            <ProformaText align={"left"}>Net Cost after Incentive</ProformaText>
            <ProformaText align={"right"}>{formatMoneyRange(calculateNetCost(data,false))}</ProformaText>
        </HStack>
        <HStack between>
            <ProformaText align={"left"}>Net Cost after Rebates</ProformaText>
            <ProformaText align={"right"}>{formatMoneyRange(calculateNetCost(data,true))}</ProformaText>
        </HStack>
    </VStack>
}

function ProformaPaybackSection({data}: { data: ProformaData }) {
    const payback = calculatePaybackPeriod(data)
    return <React.Fragment>
        <HDivider/>
        <HStack between>
            <ProformaText align={"left"}>Annual Energy Savings</ProformaText>
            <ProformaText align={"right"}>{formatMoneyRange(data.annual_savings)}</ProformaText>
        </HStack>
        {isProformaPaybackFiniteAndNotNegative(payback)&&<HStack between>
            <ProformaText align={"left"}>Payback Period</ProformaText>
            <ProformaText align={"right"}>{formatYearsRange(payback)}</ProformaText>
        </HStack>}
    </React.Fragment>
}
