import {z} from "zod";
import {Link, useNavigate} from "@tanstack/react-router";
import {trpc} from "@seeair/shared-components";
import {notifications} from "@mantine/notifications";
import {DesignedButton, TextLg, TextLink, VStack} from "@seeair/shared-components";
import { InputError, Loader} from "@mantine/core";
import React from 'react'

export const AcceptInviteSchema = z.object({hid: z.string(), code: z.string()})
export type AcceptInviteType = z.infer<typeof AcceptInviteSchema>

export function AcceptInvitePage({hid,code,shareDetails}:{hid:string,code:string,shareDetails:{shortAddress:string}|null}) {
    const navigate = useNavigate()
    const acceptInvite = trpc.HOMEOWNER.acceptShare.useMutation({
        onSuccess: ({home_id}) => {
            window.location.assign(`/homes/${home_id}`)
            // return navigate({
            //     to: '/homes/$home_id',
            //     params: {home_id},
            // })
        },
        onError: () => notifications.show({message: 'Something went wrong!'}),
    })

    return (
            !shareDetails ? (
                <VStack center>
                    <InputError>
                        This invite either does not exist or has already been used. Please ask for
                        another invite if this does not seem correct.
                    </InputError>
                    <Link to="/" preload={false}>
                        <TextLink>Go to dashboard</TextLink>
                    </Link>
                </VStack>
            ) : (
                <VStack center>
                    <TextLg>{shareDetails.shortAddress}</TextLg>
                    <DesignedButton
                        onClick={() => acceptInvite.mutate({hid, code})}
                    >
                        Accept invite
                        {acceptInvite.isPending && <Loader size="1rem"/>}
                    </DesignedButton>
                    {acceptInvite.error && (
                        <InputError>{acceptInvite.error.message}</InputError>
                    )}
                </VStack>
            )
    )
}
