import {AssessmentFile, ContractorHome, HomeAggregate} from "@seeair/schemas";
import React, {useState} from "react";
import {trpc} from "./trpc.js"
import {AbsoluteCenterStack, HStack, TextBase, TextError, VStack} from './DesignBase.js';
import {CloseButton, Loader, Modal} from "@mantine/core";
import {DigitalTwin} from './DigitalTwin.js';
import {DesignedButton} from './DesignComponents.js';

function DigitalTwinNotReady() {
    return <HStack center><TextBase>Model Not Yet Ready</TextBase></HStack>
}
export function DigitalTwinSection({file, home,isContractor,initiallyShowViewer}: { file: AssessmentFile |null | undefined | 'not_found', home: HomeAggregate | ContractorHome,isContractor:boolean, initiallyShowViewer:boolean }) {
    if(file && file != 'not_found'){
        return <InternalDigitalTwinSection file={file} home={home} initiallyShowViewer={initiallyShowViewer} isContractor={isContractor} />
    } else {
        return <DigitalTwinNotReady />
    }
}
export function InternalDigitalTwinSection({file, home,isContractor,initiallyShowViewer}: { file: AssessmentFile, home: HomeAggregate | ContractorHome,isContractor:boolean, initiallyShowViewer:boolean }) {
    const [showViewer, setShowViewer] = useState(initiallyShowViewer)
    const {
        data: urnResponse,
        error: urnError,
        isPending: urnPending
    } = trpc.HOMEOWNER.getConversionInfoForAssessmentFile.useQuery({home_id: home.home_id, s3_url: file.s3_url},{retry:false})
    if (urnPending) {
        return <VStack relative><AbsoluteCenterStack><Loader/></AbsoluteCenterStack></VStack>
    } else if (urnError) {
        return <VStack relative><AbsoluteCenterStack><TextError>Error Loading Digital
            Twin: {urnError.message}</TextError></AbsoluteCenterStack></VStack>

    } else if (!urnResponse || !urnResponse.urn || !urnResponse.objectExists || urnResponse.conversionStatus != 'success') {
        return <DigitalTwinNotReady/>
    }
    return <VStack center>
        <Modal classNames={{body: "p-0"}} withCloseButton={false} fullScreen opened={showViewer}
               onClose={() => setShowViewer(false)}>
            <VStack>
                <CloseButton size="xl" className="absolute top-2 right-2 z-50" onClick={() => setShowViewer(false)}/>
                <DigitalTwin home={home} urn={urnResponse.urn} isContractor={isContractor} />
            </VStack>
        </Modal>
        {/*{threeDThumbnailSrc && <img src={threeDThumbnailSrc.url} />}*/}
        <DesignedButton onClick={() => setShowViewer(true)}>Launch 3D Viewer</DesignedButton>
    </VStack>
}


