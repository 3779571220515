import {
    AnalysisReadyAssessment,
    Assessment,
    EnergyDataRecord,
    HomeDetails, NormalizedEnergyUsage, NormalizedEnergyUsageMonth
} from "@seeair/schemas";
import dayjs from "dayjs";
import {requiresGasInput, requiresOilInput} from './buildingScience.js';

function getMonthKeyAndEpoc(date:any):{monthKey:string,epoc:number}{
    const startOfMonth = dayjs(date).startOf('month')
    const monthKey = `${startOfMonth.year()}-${startOfMonth.month()+1}`
    const epoc = startOfMonth.unix()
    return {monthKey,epoc}
}
function validateHomeDetailsForElectrificationAndAutomatedRecommendations(homeDetails: HomeDetails) {
    const missingFields: Array<string> = []
    if (!homeDetails.yearBuilt) {
        missingFields.push("yearBuilt")
    }
    if (!homeDetails.sqft || homeDetails.sqft == 0 || homeDetails.sqft == 99999) {
        missingFields.push("sqft")
    }
    if (!homeDetails.sqft) {
        missingFields.push("beds")
    }
    if (!homeDetails.floors) {
        missingFields.push("floors")
    }
    if (!homeDetails.stove) {
        missingFields.push("stove")
    }
    if (!homeDetails.lightbulbs) {
        missingFields.push("lightbulbs")
    }
    if (!homeDetails.window_type) {
        missingFields.push("window_type")
    }
    if (!homeDetails.primary_cooling) {
        missingFields.push("primary_cooling")
    }
    if (!homeDetails.water_heater_type) {
        missingFields.push("water_heater_type")
    }
    if (!homeDetails.water_heater_age) {
        missingFields.push("water_heater_age")
    }
    // if primary cooling doesn't exist, then merv is not necessary
    if (homeDetails.primary_cooling == "None" && !homeDetails.primary_cooling_merv) {
        missingFields.push("primary_cooling_merv")
    }
    if (!homeDetails.floors) {
        missingFields.push("floors")
    }
    if (!homeDetails.primary_heating_age) {
        missingFields.push("primary_heating_age")
    }
    if (homeDetails.solar == null) {
        missingFields.push("existing solar panel")
    }
    if (missingFields.length > 0) {
        throw `missing home details: ${missingFields.join(', ')}`
    }
}
// export function assessmentIsReadyForAnalysis(assessment:Assessment):boolean {
//     try {
//         validateAssessmentForAnalysis(assessment)
//         return true
//     } catch {
//         return false
//     }
// }
export function validateAssessmentForAnalysis(assessment:Assessment):asserts assessment is AnalysisReadyAssessment {

    if (!assessment.home_details) {
        throw `home details is null`
    }
    validateHomeDetailsForElectrificationAndAutomatedRecommendations(assessment.home_details)
    if (!assessment.electric_usage) {
        throw `electric usage is null`
    }
    const electricKeys = Object.keys(assessment.electric_usage)
    if(electricKeys.length < 12) {
        throw `electric data has less then 12 months (${electricKeys.length})`
    }
    if (requiresGasInput(assessment.home_details)) {
        if (!assessment.gas_usage) {
            throw `gas usage is null and required because of home details`
        }
        const gasKeys = Object.keys(assessment.gas_usage)
        if (gasKeys.length < 12) {
            throw `gas data has less then 12 months (${gasKeys.length})`
        }
    }
    if (requiresOilInput(assessment.home_details)) {
        if (!assessment.oil_usage) {
            throw `oil usage is null and required because of home details`
        }
        const oilKeys = Object.keys(assessment.oil_usage)
        if (oilKeys.length < 12) {
            throw `oil data has less then 12 months (${oilKeys.length})`
        }
    }
    const normalizedEnergyUsage = getNormalizedEnergyUsage(assessment.home_details,assessment.electric_usage,assessment.gas_usage,assessment.oil_usage)

    const normalizedElectricKeys = Object.keys(normalizedEnergyUsage)
    if(normalizedElectricKeys.length < 12) {
        throw `electric data has less then 12 months after normalization (${normalizedElectricKeys.length})`
    }
    const normalizedGasValues = Object.values(normalizedEnergyUsage).filter(o=>o.gas_usage!=null && o.gas_cost!=null)
    if(normalizedGasValues.length < 12) {
        throw `gas data has less then 12 months after normalization (${normalizedGasValues.length})`
    }
    const normalizedOilValues = Object.values(normalizedEnergyUsage).filter(o=>o.oil_usage!=null && o.oil_cost!=null)
    if(normalizedOilValues.length < 12) {
        throw `oil data has less then 12 months after normalization (${normalizedOilValues.length})`
    }
    if (!assessment.solar_data) {
        throw `solar data missing for assessment ${assessment.assessment_id}`
    }
}
function getKey(fuel:'gas'|'electric'|'oil',costOrUsage:'cost'|'usage'):keyof NormalizedEnergyUsageMonth {
    if(costOrUsage == 'cost') {
        switch (fuel) {
            case "electric": return 'electric_cost'
            case "gas": return 'gas_cost'
            case "oil": return 'oil_cost'
        }
    } else {
        switch (fuel) {
            case "electric": return 'electric_usage'
            case "gas": return 'gas_usage'
            case "oil": return 'oil_usage'
        }
    }
}
export function getNormalizedEnergyUsage(
    home_details:HomeDetails,
    electric_usage:Record<string,EnergyDataRecord>,
    gas_usage:Record<string,EnergyDataRecord>|null|undefined,
    oil_usage:Record<string,EnergyDataRecord>|null|undefined):NormalizedEnergyUsage {
    const normalizedEnergyUsage:NormalizedEnergyUsage= {}
    const insertData = (k:'gas'|'electric'|'oil',u:EnergyDataRecord)=>{
        const {monthKey,epoc} = getMonthKeyAndEpoc(u.date)
        const billEndDate = dayjs(u.date)
        const numDaysInBill = u.num_days ?? 28
        const numDaysInCurrentMonth = billEndDate.date()
        const ratioInCurrentMonth = numDaysInCurrentMonth/numDaysInBill
        const numDaysInPriorMonth = numDaysInBill - numDaysInCurrentMonth
        if(numDaysInPriorMonth>0){
            const ratioInPriorMonth = numDaysInPriorMonth/numDaysInBill
            const {monthKey:priorMonthKey,epoc:priorEpoc} = getMonthKeyAndEpoc(billEndDate.subtract(1,'month'))
            if(!normalizedEnergyUsage[priorMonthKey]){
                normalizedEnergyUsage[priorMonthKey]={date:priorEpoc,electric_usage:0,electric_cost:0,gas_usage:0,gas_cost:0,oil_usage:0,oil_cost:0}
            }
            normalizedEnergyUsage[priorMonthKey][getKey(k,'usage')] += u.usage*ratioInPriorMonth
            normalizedEnergyUsage[priorMonthKey][getKey(k,'cost')] += u.cost*ratioInPriorMonth
        }
        if(!normalizedEnergyUsage[monthKey]){
            normalizedEnergyUsage[monthKey]={date:epoc,electric_usage:0,electric_cost:0,gas_usage:0,gas_cost:0,oil_usage:0,oil_cost:0}
        }
        normalizedEnergyUsage[monthKey][getKey(k,'usage')] += u.usage*ratioInCurrentMonth
        normalizedEnergyUsage[monthKey][getKey(k,'cost')] += u.cost*ratioInCurrentMonth
    }
    for(const e of Object.values(electric_usage)) {
        insertData('electric',e)
    }
    if (requiresGasInput(home_details)) {
        for(const g of Object.values(gas_usage!)) {
            insertData('gas',g)
        }
    }
    if (requiresOilInput(home_details)) {
        for(const g of Object.values(oil_usage!)) {
            insertData('oil',g)
        }
    }
    return normalizedEnergyUsage
}