import {
    AbsoluteCenterStack,
    FullWithDesignedSection,
    HStack,
    Text2Xl,
    TextBase,
    TextError,
    TextSm,
    VStack
} from './DesignBase.js';
import {COLOR_WHITE} from './Theme.js';
import React, {useEffect, useState} from "react";
import {
    buildShortAddress,
    extractBucketAndKeyFromHttpUrl,
    extractBucketAndKeyFromS3Url,
    getLatestFile,
    getLatestFinishedContractorAssessment
} from "@seeair/shared";
import { Breadcrumbs, Divider, Grid, Image, Loader, Tabs} from "@mantine/core";
import {Icon3dCubeSphere, IconHammer, IconPhoto} from "@tabler/icons-react";
import { ContractorAssessment, ContractorHome, ContractorTab } from "@seeair/schemas";
import {trpc} from './trpc.js';
import {Link} from "@tanstack/react-router";
import JSZip from "jszip";
import {DigitalTwinSection} from './DigitalTwinSection.js';
import {RecommendationsAccordion} from './RecommendationsAccordion.js';

export function ContractorPortalHome({home, selectedProjectId,tab}: {
    home: ContractorHome,
  selectedProjectId?: string,
    tab:ContractorTab
}) {
    const initiallyShowViewer = tab == 'viewer'
    const [selectedTab, setSelectedTab] = useState(tab)
    useEffect(() => {
        setSelectedTab(tab)
    }, [tab])
    const latestAssessment = getLatestFinishedContractorAssessment(home)
    return <FullWithDesignedSection background={COLOR_WHITE}>
        <Breadcrumbs>
            <Link to="/contractor">Contractor</Link>
            <Link to="/contractor/home/$home_id"
                  params={{home_id: home.home_id}}
                  search={{
                      project: selectedProjectId,
                      tab: selectedTab
                  }}
            >
                Home
            </Link>
        </Breadcrumbs>
        <VStack>

            <HStack leftCenter>
                <Text2Xl>{buildShortAddress(home.address)}</Text2Xl>
                <TextBase classNames={"mx-8"}>{home.owner.name}</TextBase>
                <TextBase light>{home.owner.email}</TextBase>
            </HStack>
            {
                latestAssessment == 'not_found'
                    ? <HStack center><TextBase>No Projects Ready</TextBase></HStack>
                    : <Tabs
                        value={selectedTab}
                        onChange={async (value) => {
                            setSelectedTab((value ?? 'plan') as ContractorTab)
                            const url = new URL(window.location.href);
                            if (value) {
                                url.searchParams.set('tab', value)
                                window.history.pushState({}, '', url)
                            }
                        }}
                        classNames={{tab: `border border-gray-500 aria-selected:bg-gray-200`}}
                    >
                        <Tabs.List className="justify-center">
                            <Tabs.Tab value="viewer" leftSection={<Icon3dCubeSphere/>}>Digital Twin</Tabs.Tab>
                            <Tabs.Tab value="plan" leftSection={<IconHammer/>}>Project Plan</Tabs.Tab>
                            <Tabs.Tab value="files" leftSection={<IconPhoto/>}>Photos</Tabs.Tab>
                        </Tabs.List>
                        <div className={"my-12"}>
                            <Tabs.Panel value="viewer">
                                <ContractorPortalViewer
                                    latestAssessment={latestAssessment}
                                    initiallyShowViewer={!!initiallyShowViewer}
                                    home={home}/>
                            </Tabs.Panel>
                            <Tabs.Panel value="plan">
                                <ContractorPortalProjects home={home} selectedProjectId={selectedProjectId}/>
                            </Tabs.Panel>
                            <Tabs.Panel value="files">
                                <ContractorPortalPhotos assessment={latestAssessment}/>
                            </Tabs.Panel>
                        </div>
                    </Tabs>
            }
        </VStack>
    </FullWithDesignedSection>
}

function ContractorPortalPhotos({assessment}: { assessment: ContractorAssessment }) {
    const {data: presignedImageUrls} = trpc.HOMEOWNER.getAssessmentImagesDownloadUrls.useQuery({
        assessment_id: assessment.assessment_id,
        home_id: assessment.home_id
    }, {retry: false})
    return <VStack>
        <Grid>
            {
                Object.values(assessment.assessment_files_list ?? {})
                    .filter(f => f.type.endsWith("_image"))
                    .map(fv => {
                        const presignedImageUrl = presignedImageUrls?.urls?.find(o => {
                            const {Bucket: fvBucket, Key: fvKey} = extractBucketAndKeyFromS3Url(fv.s3_url)
                            const {Bucket: presignedBucket, Key: presignedKey} = extractBucketAndKeyFromHttpUrl(o)
                            return fvBucket == presignedBucket && fvKey == presignedKey
                        })
                        return <Grid.Col key={fv.s3_url} span={{base: 12, md: 6, lg: 3}}>
                            {
                                presignedImageUrls
                                    ? <Image
                                        radius="sm"
                                        src={presignedImageUrl}
                                    />
                                    : <Loader/>
                            }

                        </Grid.Col>
                    })
            }
        </Grid>
        <HStack center><TextBase>If you require any other information, please do not hesitate to reach out to <a
            href={"mailto:support@seeair.com"}>SeeAir Support</a></TextBase></HStack>
    </VStack>
}

export function ContractorPortalProjects({home, selectedProjectId}: { home: ContractorHome, selectedProjectId?: string }) {
    return <RecommendationsAccordion
        recs={home.projects.flatMap(p=>p.recommendations)}
        projects={home.projects}
        includeProjects={true}
        isContractor={true}
        selectedProjectId={selectedProjectId}
        filteredRecs={[]}
    />
}


function ContractorPortalViewer2D({home,assessment}:{assessment:ContractorAssessment,home:ContractorHome}) {
    const [floorPlanImageUrls,setFloorPlanImageUrls] = useState([] as Array<string>)
    const {
        data:floorPlanZipUrl,
        isPending:floorPlanZipIsPending,
        error: floorPlanZipError
    } = trpc.CONTRACTOR.getAssessmentFloorPlanZipDownloadUrl.useQuery({
        home_id: home.home_id,
        assessment_id: assessment.assessment_id
    })
    useEffect(() => {
        void (async () => {
            if(floorPlanZipUrl && floorPlanZipUrl.url) {
                const zipFileResponse = await fetch(floorPlanZipUrl.url)
                const zipFileBlob = await zipFileResponse.blob()
                const zip = new JSZip();
                const content = await zip.loadAsync(zipFileBlob);
                const urls:Array<string> = [];
                for (const fileName in content.files) {
                    if (fileName.endsWith(".png")) {
                        const fileData = await content.files[fileName]!.async("blob");
                        const url:string = URL.createObjectURL(fileData);
                        urls.push(url);
                    }
                }
                setFloorPlanImageUrls(urls)
            }
        })()
    }, [floorPlanZipUrl]);
    if(floorPlanZipIsPending) {
        return <VStack classNames={"relative"}><AbsoluteCenterStack><Loader /></AbsoluteCenterStack></VStack>
    } else if(floorPlanZipError) {
        return <VStack classNames={"relative"}><AbsoluteCenterStack><TextError>Error Loading Floor Plan: {floorPlanZipError.message}</TextError></AbsoluteCenterStack></VStack>
    }
    return <VStack>
        <Grid>
            {
                floorPlanImageUrls.map((url,i) => <Grid.Col
                    key={i} span={{base: 12, lg: 6}}>
                    <Image
                        radius="sm"
                        src={url}
                    />
                    </Grid.Col>)
            }
        </Grid>
    </VStack>
}
function ContractorPortalViewer({home, initiallyShowViewer, latestAssessment}: {
    home: ContractorHome,
    initiallyShowViewer: boolean,
    latestAssessment: ContractorAssessment,
}) {
    const latest3dRenderingFile = getLatestFile(latestAssessment, 'capture_rendering_enhanced')

    return <div>

        {/*{threeDThumbnailSrc && <img src={threeDThumbnailSrc.url} />}*/}
        <Divider my="sm" label={<TextSm>3D Digital Twin</TextSm>} labelPosition="center" color={"black"}/>
        <DigitalTwinSection
            file={latest3dRenderingFile}
            home={home}
            isContractor={true}
            initiallyShowViewer={initiallyShowViewer} />
        {/*<Divider my="sm" label={<TextSm>2D Floorplan</TextSm>} labelPosition="center" color={"black"}/>*/}
        {/*{*/}
        {/*    <ContractorPortalViewer2D home={home} assessment={latestAssessment}/>*/}
        {/*}*/}

    </div>

}