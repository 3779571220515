import {
    AssessmentSchema,
    GetProjectsSchema,
    GetRecommendationsSchema,
    HomeSchema
} from "./refined-tables.js";
import {z} from 'zod'
import { RecommendationLineItemSchema} from './jsonb-schemas.js';
import {HomeOwnerSchema} from './shared.js';


export const ContractorAssessmentSchema = AssessmentSchema.pick({
    home_id:true,
    assessment_id:true,
    home_details:true,
    assessment_files_list:true,
    assessment_status:true,
    assessment_label:true
})
export type ContractorAssessment = z.infer<typeof ContractorAssessmentSchema>
export const ContractorLineItemSchema = RecommendationLineItemSchema.pick({
    line_item_id:true,
    name:true,
    quantity:true,
    description:true,
    product_id:true
})
export const ContractorLineItemsSchema = z.record(z.string(),ContractorLineItemSchema)
export const ContractorRecommendationSchema = GetRecommendationsSchema.pick({
    home_id:true,
    recommendation_id:true,
    assessment_id:true,
    project_id:true,
    original_rec_id:true,
    status:true,
    title:true
}).merge(z.object({
    line_items:ContractorLineItemsSchema.nullish()
}))
export type ContractorRecommendation = z.infer<typeof ContractorRecommendationSchema>
export const ContractorProjectSchema = GetProjectsSchema.pick({
    home_id:true,
    project_id:true,
    project_title:true,
    hubspot_deal_id:true,
    scheduled_dates:true,
    homeowner_availability:true,
    project_type: true
}).merge(z.object({
    recommendations:z.array(ContractorRecommendationSchema)
}))
export type ContractorProject = z.infer<typeof ContractorProjectSchema>

export const ContractorHomeSchema = HomeSchema.merge(z.object({
    owner:HomeOwnerSchema,
    projects:z.array(ContractorProjectSchema),
    assessments:z.array(ContractorAssessmentSchema)
}))
export type ContractorHome = z.infer<typeof ContractorHomeSchema>
