import {
    Assessment,
    AssessmentDataCollectionConfiguration,
    AssessmentDataCollectionConfigurationSchema,
    BASELINE_DATA_COLLECTION_REQUIREMENTS,
    HomeAggregate
} from "@seeair/schemas";
import {DesignedIconButton, getUseMutationOpt, TextLg, trpc, VStack} from "@seeair/shared-components";
import ReactJson, {InteractionProps} from "@microlink/react-json-view";
import React, {useState} from 'react'
import {IconDeviceFloppy} from "@tabler/icons-react";

export function AssessmentDataCollectionPanel({home, assessment}: { home: HomeAggregate, assessment: Assessment }) {
    const [validationMessage, setValidationMessage] = useState(undefined as string | undefined)
    const [dataCollectionRequirements, setDataCollectionRequirements] = useState(assessment.data_collection_requirements ?? {} as object)
    const {
        mutate,
        isPending
    } = trpc.ADMIN.overwriteDataCollectionRequirementConfig.useMutation(getUseMutationOpt(trpc.useUtils()))
    const onEdit = (edit: InteractionProps) => {
        const parse = AssessmentDataCollectionConfigurationSchema.safeParse(edit.updated_src)
        if (parse.success) {
            setDataCollectionRequirements(edit.updated_src)
            return true
        } else {
            setValidationMessage(parse.error.message)
            return false
        }
    }
    return <VStack center>
        <TextLg>Set Data Collection</TextLg>
        <ReactJson
            src={dataCollectionRequirements}
            collapsed={1}
            onEdit={onEdit}
            onAdd={onEdit}
            onDelete={onEdit}
            validationMessage={validationMessage}
        />
        <DesignedIconButton icon={<IconDeviceFloppy/>}
                            onClick={() => {
                                mutate({
                                    assessment_id: assessment.assessment_id,
                                    data_collection_requirements: dataCollectionRequirements as AssessmentDataCollectionConfiguration
                                })
                            }} disabled={isPending} tooltip={"Save"}/>
        <TextLg>Actual Data Collection (meged with defaults)</TextLg>
        <ReactJson src={{...BASELINE_DATA_COLLECTION_REQUIREMENTS, ...(assessment.data_collection_requirements ?? {})}}
                   collapsed={1}/>
    </VStack>
}