import {HomeAggregate, SeeAirPermission, Share} from "@seeair/schemas";
import { InputError, Loader, MultiSelect, NativeSelect, Select, Table, TextInput} from "@mantine/core";
import {trpc} from "./trpc.js"
import {useForm, zodResolver} from "@mantine/form";
import {z} from "zod";
import {HDivider, HStack, TextBase, TextLg, TextSmItalic, VStack} from "./DesignBase.js";
import dayjs from "dayjs";
import React, {useContext, useState} from 'react'
import {getUseMutationOpt} from './mutationHelper.js';
import {UserDataContext} from './UserDataProvider.js';
import {AdminCombobox, Badge, DeleteButton, DesignedButton, DesignedIconButton} from './DesignComponents.js';
import {isSiteAdmin} from "@seeair/shared";
import {IconDeviceFloppy} from "@tabler/icons-react";


const readWriteProps = {
    className: "min-w-48",
    classNames: {dropdown: "w-40"},
    data: [
        {label: 'Read All', value: 'read'},
        {label: 'Read / Write All', value: 'read-write'},
    ],
} as const

function getShareDisplay(share: Share): string {
    if (share.target_email.length > 0) {
        return share.target_email
    } else if (share.granted_user_id) {
        return `user_id: ${share.granted_user_id}`
    } else {
        return `company_id: ${share.granted_company_id}`
    }
}

export function SharePanel({home}: { home: HomeAggregate }) {
    const {user} = useContext(UserDataContext)
    const utils = trpc.useUtils()
    const addShare = trpc.HOMEOWNER.addShare.useMutation(getUseMutationOpt(utils))
    const updateShare = trpc.HOMEOWNER.updateShare.useMutation(getUseMutationOpt(utils))
    const removeShare = trpc.HOMEOWNER.removeShare.useMutation(getUseMutationOpt(utils))
    // console.log(`share panel user: ${JSON.stringify(user)}`)
    const form = useForm({
        initialValues: {
            target_email: '',
            access_level: 'read' as 'read' | 'read-write'
        },
        validate: zodResolver(
            z.object({
                target_email: z.string().email(),
                access_level: z.enum(['read', 'read-write']),
            })
        ),
    })


    if (!home.authorization.home.has('owner')) {
        // Should never get here, just in case.
        return (
            <VStack>
                <TextBase>You do not have permission to share this home, please ask the owner to do so.</TextBase>
            </VStack>
        )
    }

    return (

        <VStack>
            <VStack>
                <TextLg center>People with access</TextLg>
                {
                    !home.shares || home.shares.length === 0
                        ? <TextSmItalic center>You have not shared with anybody yet</TextSmItalic>
                        : <Table>
                            <Table.Tbody>
                                {home.shares.map((share) => {
                                    const isGranted = !!share.granted_user_id || !!share.granted_company_id
                                    const isExpiredIfNotGranted = dayjs(
                                        share.verification_code_expires_at
                                    ).isBefore()
                                    return <Table.Tr key={`${share.target_email}`}>
                                        <Table.Td>
                                            {getShareDisplay(share)}
                                        </Table.Td>
                                        <Table.Td>
                                            <Badge
                                                green={isGranted}
                                                gray={!isGranted}
                                            >
                                                {
                                                    isGranted
                                                        ? "Granted"
                                                        : isExpiredIfNotGranted
                                                            ? "Expired"
                                                            : "Pending"
                                                }
                                            </Badge>
                                        </Table.Td>
                                        <Table.Td>
                                        <Select
                                            {...readWriteProps}
                                            label={"Home & Assessment Permissions"}
                                            value={share.access_level}
                                            onChange={(val) => {
                                                if (!val) {
                                                    return
                                                }
                                                if (!(val === 'read' || val === 'read-write')) {
                                                    throw new Error('Invalid access level')
                                                }
                                                updateShare.mutate({
                                                    home_id: share.home_id,
                                                    target_email: share.target_email,
                                                    access_level: val,
                                                })
                                            }}
                                        />
                                        </Table.Td>
                                        <Table.Td>
                                            <MultiSelect
                                                className="mx-8 min-w-48"
                                                label="Project Read Access"
                                                // disabled={updateShare.isPending}
                                                value={Object.keys(share.project_permissions ?? {})}
                                                rightSection={updateShare.isPending && <Loader />}
                                                onChange={(ids) => {
                                                    updateShare.mutate({
                                                        home_id: share.home_id,
                                                        target_email: share.target_email,
                                                        project_permissions: simplifiedProjectListToProjectPermissions(ids),
                                                    })

                                                }}
                                                data={[
                                                    {label: "All", value: "*"},
                                                    ...home.projects.map(p => ({label: p.project_title, value: p.project_id}))
                                                ]}
                                            />
                                        </Table.Td>
                                        <Table.Td>
                                            <DeleteButton
                                                isPending={removeShare.isPending && removeShare.variables.target_email === share.target_email}
                                                onClick={() =>
                                                    removeShare.mutate({
                                                        home_id: share.home_id,
                                                        target_email: share.target_email,
                                                    })
                                                }
                                            />
                                        </Table.Td>
                                    </Table.Tr>
                                })}
                            </Table.Tbody>
                        </Table>
                }
            </VStack>
            <HDivider/>
            <VStack>
                <TextLg center>Send invite</TextLg>
                <form
                    onSubmit={form.onSubmit((values) => {
                        addShare.mutate({
                            home_id: home.home_id,
                            project_permissions: {"*": {id: "*", access: values.access_level}},
                            ...values
                        })
                    })}
                >
                    <HStack center>
                        <TextInput
                            className={"mx-4"}
                            label="Email"
                            {...form.getInputProps('target_email')}
                        />
                        <Select
                            label="Access"
                            {...readWriteProps}
                            {...form.getInputProps('access_level')}
                        />
                        <DesignedButton submit>
                            Share&nbsp;{addShare.isPending && <Loader size="1rem"/>}
                        </DesignedButton>
                    </HStack>
                    {addShare.error && (
                        <InputError>{addShare.error.message}</InputError>
                    )}
                </form>
            </VStack>
            {isSiteAdmin(user) && <React.Fragment>
                <HDivider/>
                <AdminSharePanel home={home}/>
            </React.Fragment>}
        </VStack>
    )
}

function simplifiedProjectListToProjectPermissions(ids: Array<string>): Record<string, SeeAirPermission> {
    return ids.reduce(((acc, v) => ({...acc, [v]: {id: v, access: 'read'}})), {})
}

export function AdminSharePanel({home}: { home: HomeAggregate }) {
    const {user} = useContext(UserDataContext)
    const [selectedUserId, setSelectedUserId] = useState(user?.id as string | undefined)
    const [accessLevel, setAccessLevel] = useState('read-write')
    const [projectAccess, setProjectAccess] = useState(["*"])
    const [allUsers] = trpc.ADMIN.listUsers.useSuspenseQuery()
    const {mutate: setShare, isPending} = trpc.ADMIN.setShare.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <TextLg>Add Permissions</TextLg>
        <HStack leftCenter>
            <AdminCombobox
                classNames={"mx-8"}
                label={"User"}
                initiallySelectedId={selectedUserId}
                onChange={o => setSelectedUserId(o.id)}
                disabled={false}
                options={allUsers.map(u => ({name: `${u.email} - ${u.name ?? "unknown"}`, id: u.id}))}
            />
            <NativeSelect
                label="Access"
                value={accessLevel}
                onChange={(event) => setAccessLevel(event.currentTarget.value)}
                data={[
                    {label: 'Read', value: 'read'},
                    {label: 'Read / Write', value: 'read-write'},
                ]}
            />
            <MultiSelect
                className={"mx-8 min-w-48"}
                label="Projects"
                value={projectAccess}
                onChange={setProjectAccess}
                data={[
                    {label: "All", value: "*"},
                    ...home.projects.map(p => ({label: p.project_title, value: p.project_id}))
                ]}
            />
            <DesignedIconButton
                disabled={isPending}
                icon={<IconDeviceFloppy/>}
                tooltip={"Set Permissions"}
                onClick={() => setShare({
                    home_id: home.home_id,
                    granted_user_id: selectedUserId!,
                    granted_company_id: null,
                    access_level: accessLevel as 'read' | 'read-write',
                    target_email: allUsers.find(u => u.id == selectedUserId)!.email,
                    project_permissions: simplifiedProjectListToProjectPermissions(projectAccess)
                })}
            />
        </HStack>
    </VStack>
}