import {
    Button,
    Checkbox,
    Loader,
    Modal,
    NumberInput,
    Popover,
    SegmentedControl,
    Switch,
    Table, Tabs,
    Textarea,
    TextInput
} from "@mantine/core";
import {
    Assessment,
    HomeAggregate,
    HubspotProduct,
    ProjectAggregate,
    Recommendation,
    RecommendationAggregate,
    RecommendationDiscount,
    RecommendationDiscounts,
    RecommendationLineItem,
    RecommendationLineItems,
} from "@seeair/schemas";
import {NewRecommendationInputPanel} from './NewRecommendationInputPanel.js';
import {
    AdminCombobox,
    AdminComboboxOption,
    DesignedIconButton,
    Badge,
    getUseMutationOpt,
    HDivider,
    HStack,
    NumberExpressionInput,
    ProductDataContext,
    ProjectIcon,
    RStack, Text2Xl,
    TextLg, TextSm,
    TextXs,
    trpc,
    VStack
} from "@seeair/shared-components";
import {
    IconCancel,
    IconCloudPlus,
    IconDeviceFloppy,
    IconPencil,
    IconRowInsertBottom,
    IconRowRemove,
    IconTrash
} from "@tabler/icons-react";
import React, {useContext, useEffect, useState} from "react";
import {genId, genShortId, productMatchesRecNumber, recHasLineItems, recToNum, truncateString} from "@seeair/shared";
import {Link} from "@tanstack/react-router";
import {RecommendationFinancialsTable} from './AdminRecommendationFinancialsTable.js';
import classNames from "classnames";

export function AdminRecommendationsPanel({home, assessment, selected}: {
    home: HomeAggregate,
    assessment: Assessment,
    selected?: string
}) {

    const thisRecs = home.recommendations.filter(r => r.assessment_id == assessment.assessment_id)
    const floatingRecs = home.recommendations.filter(r => !r.assessment_id)
    return (
        <VStack>
            <RecAccordion recs={thisRecs} projects={home.projects} selected={selected} assessment={assessment}/>
            <HDivider/>
            <div>
                <NewRecommendationInputPanel assessment_id={assessment.assessment_id} home_id={home.home_id}/>
            </div>
            {floatingRecs.length > 0 &&
                <RecAccordion recs={floatingRecs} projects={home.projects} assessment={assessment}/>}

        </VStack>
    )
}

export function RecAccordion({recs, projects, assessment, selected}: {
    recs: Array<RecommendationAggregate>,
    assessment: Assessment,
    projects: Array<ProjectAggregate>,
    selected?: string
}) {
    const [value, setValue] = useState(recs[0]?.recommendation_id as string | null)
    useEffect(() => {
        if (recs.map(r => r.recommendation_id).includes(selected ?? "")) {
            setValue(selected!)
        }
    }, [recs, selected])
    return <Tabs
        value={value}
        onChange={setValue}
        orientation='vertical'
        classNames={{tab:`border border-gray-500 aria-selected:bg-gray-200`}}
    >
        <Tabs.List>
            {[...recs]
                .sort((a, b) => recToNum(a, projects) - recToNum(b, projects))
                .map((r) => {
                    const proj = projects.find(p => p.project_id == r.project_id)
                    return <Tabs.Tab key={r.recommendation_id} value={r.recommendation_id}>
                        <RecControl assessment={assessment} rec={r} proj={proj} recLink={false}
                                                      projLink={true} col={true} showDelete={true} showRemove={false}/>
                    </Tabs.Tab>
                })}
        </Tabs.List>

        {[...recs]
            .sort((a, b) => recToNum(a, projects) - recToNum(b, projects))
            .map((r) => {
                const proj = projects.find(p => p.project_id == r.project_id)
                return <Tabs.Panel key={r.recommendation_id} value={r.recommendation_id}>
                    <RecPanel rec={r} proj={proj}/>
                </Tabs.Panel>
            })}
    </Tabs>
}

export function RecControl({rec, proj, assessment, recLink, projLink,col,showRemove,showDelete}: {
    rec: Recommendation,
    proj: ProjectAggregate | undefined,
    assessment: Assessment,
    recLink: boolean,
    projLink: boolean,
    col:boolean,
    showRemove:boolean,
    showDelete:boolean
}) {
    const {
        isPending: isDeleteRecPending,
        mutate: deleteRec
    } = trpc.ADMIN.deleteRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        isPending: isSetRecHiddenPending,
        isError: isSetRecHiddenError,
        mutate: setRecHidden
    } = trpc.ADMIN.setRecommendationsHidden.useMutation(getUseMutationOpt(trpc.useUtils()))
    const {
        isPending: isSetProjectPending,
        mutate: setProject
    } = trpc.ADMIN.setProjectForRecommendation.useMutation(getUseMutationOpt(trpc.useUtils()))

    return <RStack leftCenter col={col}>
        <HStack leftCenter>
            <ProjectIcon sm rec_number={rec.original_rec_id}/>
            <TextSm>{rec.original_rec_id}</TextSm>
            {recLink
                ? <Link to="/admin/assessment/$assessment_id" params={{assessment_id: assessment?.assessment_id}}
                        search={{
                            tab: "recommendations",
                            selected: rec.recommendation_id
                        }}>
                    <TextLg classNames="ml-4">{rec.title}</TextLg>
                </Link>
                : <TextLg classNames="ml-4">{rec.title}</TextLg>
            }
        </HStack>

        <HStack rightCenter={!col} leftCenter={col} classNames={classNames({"mr-10":!col})}>

            <Badge>{rec.status}</Badge>
            {proj && <Badge blue classNames="mx-2">
                {projLink
                    ? <Link className="text-white" to="/admin/assessment/$assessment_id"
                            params={{assessment_id: assessment?.assessment_id}} search={{
                        tab: "projects",
                        selected: proj.project_id
                    }}>{proj.project_title}</Link>
                    : proj.project_title
                }
            </Badge>}
            <Switch
                className="my-4"
                size="xl"
                defaultChecked={!rec.hidden || false}
                onChange={(e) => {
                    setRecHidden({
                        home_id: rec.home_id,
                        recommendation_id: rec.recommendation_id,
                        hidden: !e.currentTarget.checked
                    })
                }}
                onLabel="Visible"
                offLabel="Hidden"
                label=""
                disabled={isSetRecHiddenPending || isSetRecHiddenError}
            />
            { proj && showRemove && <DesignedIconButton
                        tooltip={`Remove from project`}
                        onClick={() => setProject({
                            home_id: proj.home_id,
                            recommendation_id: rec.recommendation_id,
                            project_id: null,
                            selected: false
                        })}
                        disabled={isSetProjectPending}
                        icon={isSetProjectPending ? <Loader size="sm"/> : <IconRowRemove/>}
                    />
            }
            { showDelete && <DesignedIconButton
                        tooltip="delete rec"
                        onClick={() => {
                            deleteRec({
                                recommendation_id: rec.recommendation_id
                            })
                        }}
                        icon={<IconTrash size="1rem"/>}
                        disabled={isDeleteRecPending}
                        stopPropagation
                    />
            }

        </HStack>
    </RStack>
}

function LineItemInputRow({li, data, setData, filteredProducts, editing, isPending}: {
    editing: boolean,
    isPending: boolean,
    filteredProducts: Array<AdminComboboxOption & HubspotProduct>,
    li: RecommendationLineItem,
    data: RecommendationLineItems,
    setData: (data: RecommendationLineItems) => void
}) {
    const [showingDetailsModal, setShowingDetailsModal] = useState(false)
    return <Table.Tr>
        <Table.Td>{truncateString(li.line_item_id, 6)}</Table.Td>
        <Table.Td>
            <AdminCombobox
                initiallySelectedId={li.product_id}
                options={filteredProducts}
                disabled={!editing || isPending}
                onChange={p => {
                    const newData: RecommendationLineItems = {...data}
                    const newRow: RecommendationLineItem = {...newData[li.line_item_id]!}
                    newRow.product_id = p.id
                    newRow.price_per_unit = parseFloat((p as unknown as HubspotProduct).properties.price ?? "0")
                    newRow.name = p.name
                    newData[li.line_item_id] = newRow
                    setData(newData)
                }}/></Table.Td>
        <Table.Td>
            <NumberExpressionInput
                value={li.quantity}
                onChange={(newQuantity) => {
                    const newData: RecommendationLineItems = {...data}
                    const newRow: RecommendationLineItem = {...newData[li.line_item_id]!}
                    newRow.quantity = parseFloat(`${newQuantity}`)
                    newData[li.line_item_id] = newRow
                    setData(newData)
                }}
                disabled={!editing || isPending}
            />
        </Table.Td>
        <Table.Td>
            <NumberExpressionInput
                value={li.price_per_unit}
                onChange={(newPrice) => {
                    const newData: RecommendationLineItems = {...data}
                    const newRow: RecommendationLineItem = {...newData[li.line_item_id]!}
                    newRow.price_per_unit = parseFloat(`${newPrice}`)
                    newData[li.line_item_id] = newRow
                    setData(newData)
                }}
                disabled={!editing || isPending}
                money
            />
        </Table.Td>
        <Table.Td>
            <Popover width={300} trapFocus position="bottom" withArrow shadow="md">
                <Popover.Target>
                    <Button><TextXs>{truncateString(li.description ?? "Customize", 15)}</TextXs></Button>
                </Popover.Target>
                <Popover.Dropdown>
                    <Textarea size="xs" value={li.description ?? undefined} placeholder={"Description..."}
                              onChange={(e) => {
                                  const newData: RecommendationLineItems = {...data}
                                  const newRow: RecommendationLineItem = {...newData[li.line_item_id]!}
                                  newRow.description = e.currentTarget.value
                                  newData[li.line_item_id] = newRow
                                  setData(newData)
                              }}/>
                </Popover.Dropdown>
            </Popover>
        </Table.Td>
        <Table.Td>
            <DesignedIconButton
                tooltip="Remove Line Item"
                onClick={() => {
                    const rest: RecommendationLineItems = {...data}
                    console.log(`current ids: ${JSON.stringify(Object.keys(rest))} - deleting: ${li.line_item_id}`)
                    delete rest[li.line_item_id]
                    setData(rest)
                }}
                disabled={!editing || isPending}
                icon={<IconTrash/>}
            />
        </Table.Td>
    </Table.Tr>
}

function CreateHubspotProductPanel({initialRecommendationId, close}: {
    initialRecommendationId: string,
    close: () => void
}) {
    const [name, setName] = useState("")
    const [price, setPrice] = useState("")
    const [description, setDescription] = useState("")
    const [recommendation_id, setRecommendation_id] = useState(initialRecommendationId)
    const [seeair_part_number, setPartNumber] = useState("")
    const {
        mutate,
        isPending
    } = trpc.ADMIN.createHubspotProduct.useMutation(getUseMutationOpt(trpc.useUtils(), () => {
        setName("")
        setPrice("")
        setDescription("")
        setRecommendation_id(initialRecommendationId)
        close()
    }))
    return <VStack>
        <TextInput label="Product Name" value={name} onChange={(e) => setName(e.currentTarget.value)}/>
        <TextInput label="Product Price" value={price} onChange={(e) => setPrice(e.currentTarget.value)}/>
        <TextInput label="Product Description" value={description}
                   onChange={(e) => setDescription(e.currentTarget.value)}/>
        <TextInput label="Recommendation Numbers" value={recommendation_id}
                   onChange={(e) => setRecommendation_id(e.currentTarget.value)}/>
        <TextInput label="Part Numbers" value={seeair_part_number}
                   onChange={(e) => setPartNumber(e.currentTarget.value)}/>
        <DesignedIconButton disabled={isPending} icon={<IconDeviceFloppy/>} tooltip="Create" onClick={() => {
            mutate({
                name,
                price,
                description,
                recommendation_id,
                seeair_part_number
            })
        }}/>
    </VStack>
}

function RecommendationLineItemsTable({rec}: { rec: RecommendationAggregate }) {
    const [showCreateProductModel, setShowCreateProductModal] = useState(false)
    const {products} = useContext(ProductDataContext)
    const filteredProducts = products
        .filter(p => !p.properties.recommendation_id || productMatchesRecNumber(p, rec.original_rec_id))
        .map(p => ({...p, name: p.properties.name ?? ""}))
    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(rec.line_items ?? {})
    const {
        mutate,
        isPending
    } = trpc.ADMIN.setRecommendationLineItems.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))
    return <VStack hAuto classNames="my-4">
        <Table
            striped
            withTableBorder
        >
            <Table.Thead>
                <Table.Tr>
                    <Table.Td colSpan={10}><VStack center><TextSm>Line Items</TextSm></VStack></Table.Td>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th>line_item_id</Table.Th>
                    <Table.Th>product</Table.Th>
                    <Table.Th>quantity</Table.Th>
                    <Table.Th>price_per_unit</Table.Th>
                    <Table.Th>description</Table.Th>
                    <Table.Th><HStack rightCenter>
                        {editing && <DesignedIconButton
                            tooltip="Save Lineitems"
                            disabled={isPending}
                            icon={<IconDeviceFloppy/>}
                            onClick={() => {
                                mutate({
                                    recommendation_id: rec.recommendation_id,
                                    line_items: data
                                })
                            }}/>}
                        <DesignedIconButton
                            tooltip={editing ? "Cancel" : "Edit Lineitems"}
                            onClick={() => {
                                if (editing) {
                                    setData(rec.line_items ?? {})
                                }
                                setEditing(!editing)
                            }}
                            disabled={isPending}
                            icon={editing ? <IconCancel/> : <IconPencil/>}
                        />
                    </HStack></Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    Object.values(data).map(li => <LineItemInputRow
                        key={li.line_item_id}
                        li={li}
                        data={data}
                        setData={setData}
                        filteredProducts={filteredProducts}
                        editing={editing}
                        isPending={isPending}
                    />)
                }
            </Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Td colSpan={10}><VStack center><TextSm>{`${Object.keys(rec.line_items ?? {}).length} line items defined`}</TextSm></VStack></Table.Td>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
        <HStack>
            {editing && <AdminCombobox
                options={filteredProducts}
                onChange={(p) => {
                    console.log(`Product chosen: ${JSON.stringify(p)}`)
                    const line_item_id = genId("li")
                    const newData: RecommendationLineItems = {
                        ...data,
                        [line_item_id]: {
                            line_item_id,
                            name: p.name,
                            quantity: 1,
                            price_per_unit: parseFloat((p as unknown as HubspotProduct).properties.price ?? "0"),
                            product_id: p.id,
                            discounts: {}
                        }
                    }
                    setData(newData)
                }}
                disabled={isPending}
            />}
            {editing && <DesignedIconButton
                tooltip="Create Product"
                onClick={() => {
                    setShowCreateProductModal(true)
                }}
                disabled={isPending}
                icon={<IconCloudPlus/>}
            />}
        </HStack>
        <Modal title="Create Hubspot Product"
               opened={showCreateProductModel}
               onClose={() => {
                   setShowCreateProductModal(false)
               }}>
            <CreateHubspotProductPanel initialRecommendationId={rec.original_rec_id}
                                       close={() => setShowCreateProductModal(false)}/>
        </Modal>
    </VStack>
}

function RecommendationDiscountsTable({rec}: { rec: RecommendationAggregate }) {
    const {incentives} = useContext(ProductDataContext)
    const filteredIncentives = incentives
        .filter(i => i.recNums.includes(rec.original_rec_id))
        .map(i => ({...i, name: i.title, id: i.incentive_id}))

    const [editing, setEditing] = useState(false)
    const [data, setData] = useState(rec.discounts ?? {})
    const {
        mutate,
        isPending
    } = trpc.ADMIN.setRecommendationDiscounts.useMutation(getUseMutationOpt(trpc.useUtils(), () => setEditing(false)))

    return <VStack hAuto classNames="my-4">
        <Table striped withTableBorder>
            <Table.Thead>
                <Table.Tr>
                    <Table.Td colSpan={10}><VStack center><TextSm>Discounts</TextSm></VStack></Table.Td>
                </Table.Tr>
                <Table.Tr>
                    <Table.Th>incentive_id</Table.Th>
                    <Table.Th>title</Table.Th>
                    <Table.Th>RecNums</Table.Th>
                    <Table.Th>timeOfPurchase</Table.Th>
                    <Table.Th>discountOnRemainder</Table.Th>
                    <Table.Th>url</Table.Th>
                    <Table.Th>percentage</Table.Th>
                    <Table.Th>cap</Table.Th>
                    <Table.Th>order</Table.Th>
                    <Table.Th className="w-20"><HStack rightCenter>
                        {editing && <DesignedIconButton
                            tooltip="Save All Incentives"
                            disabled={false}
                            icon={<IconDeviceFloppy/>}
                            onClick={() => {
                                mutate({
                                    recommendation_id: rec.recommendation_id,
                                    discounts: data
                                })
                            }}/>}
                        <DesignedIconButton
                            tooltip={editing ? "Cancel" : "Edit incentives"}
                            onClick={() => {
                                if (editing) {
                                    setData(rec.discounts ?? {})
                                }
                                setEditing(!editing)
                            }}
                            disabled={isPending}
                            icon={editing ? <IconCancel/> : <IconPencil/>}
                        />
                    </HStack></Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {
                    Object.values(data).sort((d1, d2) => d1.order - d2.order).map(d => <DiscountInputRow
                        key={d.incentive_id}
                        incentive_id={d.incentive_id}
                        data={data}
                        editing={editing}
                        setData={setData}/>)
                }

            </Table.Tbody>
            <Table.Tfoot>
                <Table.Tr>
                    <Table.Td colSpan={10}><VStack center><TextSm light>{`${Object.keys(rec.discounts ?? {}).length} discounts defined`}</TextSm></VStack></Table.Td>
                </Table.Tr>
            </Table.Tfoot>
        </Table>
        <HStack>
            {editing && <AdminCombobox
                options={filteredIncentives}
                onChange={d => {
                    const newData: RecommendationDiscounts = {
                        ...data,
                        [d.id]: d as unknown as RecommendationDiscount
                    }
                    setData(newData)
                }}
                disabled={isPending}/>
            }
            {editing && <DesignedIconButton
                tooltip="Add custom incentive"
                onClick={() => {
                    const incentive_id = genShortId("incentive")
                    const newIncentive: RecommendationDiscount = {
                        incentive_id,
                        title: "",
                        timeOfPurchase: false,
                        discountOnRemainder: false,
                        url: "",
                        percentage: 0,
                        cap: 0,
                        recNums: [rec.original_rec_id],
                        order: 10
                    }
                    const newData: RecommendationDiscounts = {
                        ...data,
                        [incentive_id]: newIncentive
                    }
                    setData(newData)
                }}
                disabled={isPending}
                icon={<IconRowInsertBottom/>}
            />
            }
        </HStack>
    </VStack>
}

function DiscountInputRow({editing, data, setData, incentive_id}: {
    editing: boolean,
    data: RecommendationDiscounts,
    setData: (data: RecommendationDiscounts) => void,
    incentive_id: string
}) {
    const rowData: RecommendationDiscount = data[incentive_id]!
    const changeRowData = (k: keyof RecommendationDiscount, v: any) => {
        const newIncentiveRow = {...rowData, [k]: v}
        const newData = {...data}
        newData[incentive_id] = newIncentiveRow
        setData(newData)
    }
    const removeRow = () => {
        const newData = {...data}
        delete newData[incentive_id]
        setData(newData)
    }
    return <Table.Tr>
        <Table.Td>{incentive_id}</Table.Td>
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.title}
                onChange={(event) => changeRowData('title', event.currentTarget.value)}
            />
        </Table.Td>
        <Table.Td>
            {JSON.stringify(rowData.recNums)}
        </Table.Td>
        <Table.Td>
            <Checkbox
                disabled={!editing}
                checked={rowData.timeOfPurchase}
                onChange={(event) => changeRowData('timeOfPurchase', event.currentTarget.checked)}
            />
        </Table.Td>
        <Table.Td>
            <Checkbox
                disabled={!editing}
                checked={rowData.discountOnRemainder}
                onChange={(event) => changeRowData('discountOnRemainder', event.currentTarget.checked)}
            />
        </Table.Td>
        <Table.Td>
            <TextInput
                disabled={!editing}
                value={rowData.url}
                onChange={(event) => changeRowData('url', event.currentTarget.value)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.percentage}
                onChange={(value) => changeRowData('percentage', value)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.cap}
                onChange={(value) => changeRowData('cap', value)}
            />
        </Table.Td>
        <Table.Td>
            <NumberInput
                disabled={!editing}
                value={rowData.order}
                onChange={(value) => changeRowData('order', value)}
            />
        </Table.Td>
        <Table.Td>
            {editing && <DesignedIconButton
                tooltip="Remove Incentive"
                onClick={removeRow}
                icon={<IconTrash/>}
                disabled={false}/>}
        </Table.Td>
    </Table.Tr>
}

type UseLineItems = 'line_items' | 'estimates'

function RecPanel({rec}: { rec: RecommendationAggregate, proj: ProjectAggregate | undefined }) {
    const [useLineItems, setUseLineItems] = useState(Object.values(rec.line_items ?? {}).length > 0 ? 'line_items' : 'estimates')

    const {
        mutate,
        isPending,
    } = trpc.ADMIN.setRecommendationLineItems.useMutation(getUseMutationOpt(trpc.useUtils()))
    return <VStack>
        <Text2Xl center>{rec.title}</Text2Xl>
        <HStack leftCenter hAuto>
            <TextLg>Cost Representation</TextLg>
            <SegmentedControl
                data={['line_items', 'estimates']}
                value={useLineItems as string}
                onChange={(value) => {
                    setUseLineItems(value as UseLineItems)
                }}/>
            {
                useLineItems == 'estimates' && <DesignedIconButton
                    disabled={isPending || !recHasLineItems(rec)}
                    icon={<IconTrash/>}
                    onClick={() => {
                        mutate({recommendation_id: rec.recommendation_id, line_items: {}})
                    }}
                    tooltip="Delete All LineItems"/>
            }
        </HStack>
        {
            useLineItems == 'line_items' &&
            <React.Fragment>
                <RecommendationLineItemsTable
                    rec={rec}/>
            </React.Fragment>
        }
        <RecommendationDiscountsTable rec={rec}/>
        <RecommendationFinancialsTable rec={rec} includeEstimates={useLineItems != 'line_items'}/>
    </VStack>
}