import {RHideStack, VStack} from './DesignBase.js';
import React, {useMemo, useState} from "react";
import {Viewer} from './Viewer.js';
import {ContractorHome, ContractorRecommendation, HomeAggregate, Recommendation} from "@seeair/schemas";
import {RecommendationsAccordion} from './RecommendationsAccordion.js';
import classNames from "classnames";
import {IsHomeAggregate} from "@seeair/shared";

export function DigitalTwin({home, urn,isContractor}: { home: HomeAggregate | ContractorHome, urn: string,isContractor:boolean }) {
    const [selectedRecs, setSelectedRecs] = useState([] as Array<number>)
    const recs:Array<Recommendation>|Array<ContractorRecommendation> = useMemo(() => IsHomeAggregate(home)
        ? home.recommendations
        : home.projects.flatMap(p=>p.recommendations),[home]) //without this, the viewer remounts on every change
    return <RHideStack xl reverse>
        <Viewer
            urn={urn}
            onRecToggleChange={(selectedRecNums) => {
                setSelectedRecs(selectedRecNums)
            }}
            recs={recs}
            isContractor={isContractor}
            className={classNames("h-screen w-screen",{
                " xl:w-3/5vw":!isContractor
            })}/> {/*this should always be visible*/}
        <VStack classNames="max-w-2/5 min-w-2/5 h-screen bg-slate-200 w-2/5 max-h-screen overflow-auto"
                    style={{width: "40vw"}}>
                <RecommendationsAccordion
                    recs={recs}
                    projects={home.projects}
                    filteredRecs={selectedRecs}
                    includeProjects={true}
                    isContractor={isContractor}
                /> {/*this should be hidden unless on xl screen or if contractor is viewing*/}
            </VStack>


    </RHideStack>
}