import {DesignedLink} from './DesignComponents.js';
import {COLOR_DARK_GREY, COLOR_WHITE, PRIMARY_LIGHT_BLUE} from './Theme.js';
import {Logo} from './Icons.js';
import React, {ReactElement} from "react";
import {Avatar, Burger, Menu, UnstyledButton} from "@mantine/core";
import {TextBase, TextXs, VStack} from './DesignBase.js';
import {Link, useNavigate, useRouter} from "@tanstack/react-router";
import {signOut} from "./clientAuth.js";
import classNames from "classnames";
import {isContractor, isHomeOwner, isSiteAdmin, truncateEmail} from "@seeair/shared";
import {SeeAirUser} from "@seeair/schemas";

function HeaderLink({text, to, active}: { text: string, to: string, active?: boolean }) {
    return <DesignedLink
        to={to}
        style={{
            borderRadius: "6.25rem",
            textDecoration: "none"
        }}
        inactiveProps={{
            style: {
                color: COLOR_DARK_GREY,
                backgroundColor: "transparent"
            }
        }}
        activeProps={{
            style: {
                color: COLOR_WHITE,
                backgroundColor: PRIMARY_LIGHT_BLUE
            }
        }}
    ><span className="mx-4 py-2 px-4 text-xl font-light">{text}</span></DesignedLink>
}


export function LoginAvatar({user, isLoading}: { user: SeeAirUser | null | undefined, isLoading: boolean }) {
    const navigate = useNavigate()
    const router = useRouter()
    let component: ReactElement
    if (user) {
        component = <Menu>
            <Menu.Target>
                <UnstyledButton>
                    <VStack center>
                        <Avatar
                            src={user.image}
                            radius="xl"
                            imageProps={{referrerPolicy: 'no-referrer'}}
                        />
                        <TextBase m0>{user.name}</TextBase>
                        <TextXs light m0>{truncateEmail(user.email, 20)}</TextXs>
                    </VStack>
                </UnstyledButton>
            </Menu.Target>
            <HeaderDropdown hidePages user={user}/>
        </Menu>
    } else {
        component = <UnstyledButton disabled={isLoading} onClick={async () => {
            await router.invalidate()
            await navigate({to: '/auth/signin'})
        }}>
            <VStack center classNames={classNames({"opacity-50": isLoading})}>
                <Avatar
                    src={null}
                    radius="xl"
                />
                <TextBase m0>{isLoading ? "..." : "Log In"}</TextBase>
            </VStack>
        </UnstyledButton>
    }
    return (
        <div className="ml-4 w-36 h-20 flex flex-row justify-center items-start">
            {component}
        </div>
    )
}

export function HeaderDropdown({hidePages, user}: { user: SeeAirUser | undefined | null, hidePages?: boolean }) {
    const navigate = useNavigate()
    const router = useRouter()
    // console.log(`user: ${JSON.stringify(user)}`)
    return <Menu.Dropdown>
        {!hidePages &&
            <Menu.Item component={Link} to="/blog">
                Blog
            </Menu.Item>
        }
        {!hidePages &&
            <Menu.Item component={Link} to="/about">
                About
            </Menu.Item>
        }
        {isHomeOwner(user) && <Menu.Item component={Link} to="/homes">
            View Your Homes
        </Menu.Item>
        }
        {(isHomeOwner(user) || isSiteAdmin(user)) && <Menu.Item component={Link} to="/webview/app-redirect">
            Launch App
        </Menu.Item>
        }
        {isSiteAdmin(user) && (
            <Menu.Item component={Link} to="/admin">
                Admin
            </Menu.Item>
        )}
        {isContractor(user) && (
            <Menu.Item component={Link} to="/contractor">
                Contractor
            </Menu.Item>
        )}
        {
            user
                ? <Menu.Item component={Link} onClick={() => signOut({callbackUrl: '/'})}>
                    Sign Out<br/><TextXs light m0>({truncateEmail(user.email, 20)})</TextXs>
                </Menu.Item>
                : <Menu.Item component={Link} onClick={async () => {
                    console.log("navigate /auth/signin")
                    await router.invalidate()
                    await navigate({to: '/auth/signin'})
                }}>
                    Sign In
                </Menu.Item>
        }
    </Menu.Dropdown>
}

export function DesignedNavHeader({user, isLoading}: { user: SeeAirUser | null | undefined, isLoading: boolean }) {
    return <div className={classNames(
        "flex flex-row",
        "items-center justify-between",
        "w-full min-h-44 px-4 lg:px-16"
    )}
                style={{
                    background: "rgba(246, 246, 246, 0.75)"
                }}>
        <div className={"px-4"}><DesignedLink to={"/"}><Logo/></DesignedLink></div>
        <div className="flex flex-row items-center justify-end">
            <div className="block sm:hidden">
                <Menu>
                    <Menu.Target>
                        <UnstyledButton>
                            <Burger/>
                        </UnstyledButton>
                    </Menu.Target>
                    <HeaderDropdown user={user}/>
                </Menu>
            </div>
            <div className="hidden md:flex ">
                {isSiteAdmin(user) && <HeaderLink text="Admin" to="/admin"/>}
                {isHomeOwner(user) && <HeaderLink text="Homes" to="/homes"/>}
                {isContractor(user) && <HeaderLink text="Contractor" to="/contractor"/>}
                <HeaderLink text="Blog" to="/blog"/>
                <HeaderLink text="About" to="/about"/>
            </div>
            <div className="hidden sm:flex">
                <LoginAvatar user={user} isLoading={isLoading}/>
            </div>
        </div>
    </div>
}