import {createFileRoute, useNavigate} from '@tanstack/react-router'
import {
	AuthenticationCheck,
	Layout,
	trpc
} from "@seeair/shared-components";
import {
	AcceptInvitePage,
	AcceptInviteSchema,
	AcceptInviteType
} from "@seeair/homeowner-components";
import {AbsoluteCenterStack, TextError} from "@seeair/shared-components";
import {Loader} from "@mantine/core";
import React, {ReactNode, useEffect} from "react";
import {Helmet} from "react-helmet-async";
import {isHomeOwner, isSiteAdmin} from "@seeair/shared";

export const Route = createFileRoute('/shares/accept-invite')({
	validateSearch: (data): AcceptInviteType | Record<string, never> => {
		return AcceptInviteSchema.parse(data)
	},
	component: function AcceptInvitePageRoute(){
		const {hid, code} = Route.useSearch()
		const {data:home,isLoading:homeIsLoading,error:homeError} = trpc.HOMEOWNER.getHomeAggregate.useQuery({home_id: hid})
		const {data:shareDetails,isLoading:shareIsLoading,error:shareError} = trpc.HOMEOWNER.getShareDetails.useQuery({hid, code})
		const navigate = useNavigate()
		useEffect(()=>{
			void (async () => {
				if(home) {
					await navigate({
						to: '/homes/$home_id',
						params: {home_id: home.home_id}
					})
				}
			})()

		},[home,navigate])
		let mainComponent:ReactNode
		if(homeIsLoading || shareIsLoading) {
			mainComponent = <AbsoluteCenterStack><Loader /></AbsoluteCenterStack>
		} else if(shareError) {
			mainComponent = <AbsoluteCenterStack><TextError>{shareError.message}</TextError></AbsoluteCenterStack>
		} else {
			mainComponent = <AcceptInvitePage hid={hid} code={code} shareDetails={shareDetails!} />
		}
		return <Layout>
			<AuthenticationCheck
				shouldRedirect={(user) => !isSiteAdmin(user) && !isHomeOwner(user)}
				redirectTo="/auth/signin"
				redirectSearch={{redirect: location.pathname+(location.search??"")}}
				notify={"Please register to accept invite"}
			>
				<Helmet>
				<title>SeeAir</title>
				<meta name="description"
					  content="SeeAir"/>
			</Helmet>
				{mainComponent}
			</AuthenticationCheck>
		</Layout>
	}
})